import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import {
	Table,
	Input,
	Spin,
	Checkbox,
	Popconfirm,
	Button,
	Drawer,
	Modal,
} from "antd";
import {
	EyeOutlined,
	EditOutlined,
	DeleteOutlined,
	FilterOutlined,
} from "@ant-design/icons";
import "./Inventory.css";
import Main from "./Layout";
import EditProduct from "./EditProduct";
import { useNavigate } from "react-router-dom";
import VendorSpecificProduct from "./AdminSpecificProduct";
const { Search } = Input;

const AdminInventory = () => {
	const { apiurl, token, currentCountry } = useAuth();
	const [products, setProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [subcategories, setSubcategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedSubcategories, setSelectedSubcategories] = useState([]);
	const [viewingProduct, setViewingProduct] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [visible, setVisible] = useState(false);
	const [editVisible, setEditVisible] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [vendors, setVendors] = useState([]);
	const [selectedVendor, setSelectedVendor] = useState([]);

	const navigate = useNavigate();

	const getProducts = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/admin/products/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				setProducts(data);
				setFilteredProducts(data);

				const uniqueSubcategories = [
					...new Set(data.map((product) => product.sub_category?.name)),
				];
				const uniqueVendors = [
					...new Set(data.map((product) => product.vendor?.business_name)),
				];
				setSubcategories(uniqueSubcategories);
				setVendors(uniqueVendors);
			}
		} catch (error) {
			console.error("Error fetching products:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async (id) => {
		try {
			const response = await fetch(`${apiurl}/products/${id}/`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});

			if (response.ok) {
				console.log("Product deleted successfully with id:", id);

				setProducts((prevProducts) =>
					prevProducts.filter((product) => product.id !== id)
				);
				setFilteredProducts((prevFilteredProducts) =>
					prevFilteredProducts.filter((product) => product.id !== id)
				);
			} else {
				console.error("Failed to delete product", response);
			}
		} catch (error) {
			console.error("Error deleting product:", error);
			alert("An error occurred while deleting the product. Please try again.");
		}
	};

	const handleSubcategoryChange = (checkedValues) => {
		setSelectedSubcategories(checkedValues);
	};

	const handleSearch = (value) => {
		setSearchText(value);
	};

	const getFilteredProducts = () => {
		let filtered = products;

		if (selectedSubcategories.length > 0) {
			filtered = filtered.filter((product) =>
				selectedSubcategories.includes(product.sub_category?.name)
			);
		}

		if (selectedVendor.length > 0) {
			filtered = filtered.filter((product) =>
				selectedVendor.includes(product.vendor?.business_name)
			);
		}

		if (searchText) {
			filtered = filtered.filter((product) =>
				product.name?.toLowerCase().includes(searchText.toLowerCase())
			);
		}

		return filtered;
	};

	useEffect(() => {
		setFilteredProducts(getFilteredProducts());
	}, [products, selectedSubcategories, searchText, selectedVendor]);

	useEffect(() => {
		getProducts();
	}, []);

	const handleView = (id) => {
		setViewingProduct(id);
	};

	const handleEdit = (id) => {
		const pd = {
			id: id.id,
			name: id.name,
			mainCategory: id.maincategory.id,
			category: id.category.id,
			subcategory: id.sub_category.id,
			description: id.description,
		};
		setSelectedProduct(pd);
		setEditVisible(true);
	};

	const closeEdit = () => {
		setEditVisible(false);
		getProducts();
		setSelectedProduct(null);
	};

	const formatDate = (date) => {
		const result = new Date(date);
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
		};
		return result.toLocaleDateString("en-US", options);
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (_, record) => {
				const imageUrl = record.product_varients?.[0]?.images?.[0]?.image;
				return imageUrl ? (
					<div className="record-name">
						<img src={`${apiurl}/${imageUrl}`} alt="Product" />{" "}
						<p>{record?.name}</p>
					</div>
				) : (
					"No Image"
				);
			},
			sorter: (a, b) => a?.name?.localeCompare(b?.name),
		},
		{
			title: "Vendor",
			dataIndex: ["vendor", "business_name"],
			key: "sub_category",
			sorter: (a, b) =>
				a.vendor?.business_name.localeCompare(b.vendor?.business_name),
		},
		{
			title: "Sub Category",
			dataIndex: ["sub_category", "name"],
			key: "sub_category",
			sorter: (a, b) =>
				a.sub_category?.name.localeCompare(b.sub_category?.name),
		},
		{
			title: "Stock Quantity",
			key: "stock_quantity",
			render: (_, record) =>
				record.product_varients?.[0]?.stock_quantity || "N/A",
			sorter: (a, b) =>
				a.product_varients?.[0]?.stock_quantity -
				b.product_varients?.[0]?.stock_quantity,
		},
		{
			title: "Added",
			key: "created_at",
			dataIndex: "created_at",
			render: (text) => formatDate(text),
			sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
		},
		{
			title: "Price",
			key: "price",
			render: (_, record) => {
				const varient = record.product_varients?.[0];
				const countryName = currentCountry.name;

				const countryPriceData = varient?.country_specific_prices?.find(
					(price) => price.country_name === countryName
				);

				const countryPrice = countryPriceData?.price || varient?.price;

				return countryPrice ? `${currentCountry.symbol}${countryPrice}` : "N/A";
			},
			sorter: (a, b) => {
				const varientA = a.product_varients?.[0];
				const varientB = b.product_varients?.[0];
				const countryName = currentCountry.name;

				const countryPriceA =
					varientA?.country_specific_prices?.find(
						(price) => price.country_name === countryName
					)?.price ||
					varientA?.price ||
					0;

				const countryPriceB =
					varientB?.country_specific_prices?.find(
						(price) => price.country_name === countryName
					)?.price ||
					varientB?.price ||
					0;

				return countryPriceA - countryPriceB;
			},
		},
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<div>
					<EyeOutlined
						style={{ marginRight: 16, cursor: "pointer", fontSize: "20px" }}
						onClick={() => handleView(record.id)}
					/>
					<EditOutlined
						style={{ marginRight: 16, cursor: "pointer", fontSize: "20px" }}
						onClick={() => handleEdit(record)}
					/>
					<Popconfirm
						title="Are you sure you want to delete this product?"
						onConfirm={() => handleDelete(record.id)}
						okText="Yes"
						cancelText="No">
						<DeleteOutlined
							style={{ cursor: "pointer", color: "red", fontSize: "20px" }}
						/>
					</Popconfirm>
				</div>
			),
		},
	];

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const handleBack = () => {
		setViewingProduct(null);
		console.log("Pressed");
	};

	const handleVendorChange = (checkedValues) => {
		setSelectedVendor(checkedValues);
	};

	return (
		<Main>
			{viewingProduct ? (
				<VendorSpecificProduct handleBack={handleBack} id={viewingProduct} />
			) : (
				<div className="inventory-container">
					<div className="inventory-actions">
						<Search
							className="inventory-search"
							placeholder="Search by product name"
							onSearch={handleSearch}
							onChange={(e) => handleSearch(e.target.value)}
							value={searchText}
							enterButton
						/>

						<Button
							className="inventory-filter-btn"
							icon={<FilterOutlined />}
							onClick={showDrawer}>
							Filter
						</Button>
					</div>

					<Drawer
						title="Filter Products"
						placement="left"
						onClose={onClose}
						open={visible}
						width={300}>
						<div className="inventory-filter">
							<h3>Filter by Subcategory</h3>
							<Checkbox.Group
								options={subcategories.map((subcategory) => ({
									label: subcategory,
									value: subcategory,
								}))}
								value={selectedSubcategories}
								onChange={handleSubcategoryChange}
							/>
						</div>
						<div className="inventory-filter">
							<h3>Filter by Vendor</h3>
							<Checkbox.Group
								options={vendors.map((vendor) => ({
									label: vendor,
									value: vendor,
								}))}
								value={selectedVendor}
								onChange={handleVendorChange}
							/>
						</div>
					</Drawer>

					<Spin spinning={loading}>
						<Table
							columns={columns}
							dataSource={filteredProducts.map((item, index) => ({
								key: index,
								...item,
							}))}
							pagination={{ pageSize: 10 }}
							rowKey={(record) => record.id}
						/>
					</Spin>
				</div>
			)}
			<Modal
				title="Edit Product"
				open={editVisible}
				footer={null}
				onCancel={closeEdit}>
				<EditProduct onClose={closeEdit} product={selectedProduct} />
			</Modal>
		</Main>
	);
};

export default AdminInventory;

import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./BlogPostForm.css";
import { useAuth } from "../utils/useAuth";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";

function BlogPostForm() {
	const navigate = useNavigate();
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [thumbnail, setThumbnail] = useState(null);
	const [previewUrl, setPreviewUrl] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const { token, apiurl } = useAuth();

	const modules = {
		toolbar: [
			[{ header: [1, 2, 3, false] }],
			["bold", "italic", "underline", "strike"],
			[{ list: "ordered" }, { list: "bullet" }],
			["link", "image"],
			["clean"],
			[{ align: [] }],
			["code-block"],
		],
	};

	const handleThumbnailChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setThumbnail(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreviewUrl(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		const formData = new FormData();
		formData.append("title", title);
		formData.append("content", content);
		if (thumbnail) {
			formData.append("thumbnail", thumbnail);
		}

		try {
			const response = await fetch(`${apiurl}/blogposts/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			if (!response.ok) {
				message.error(`Error: ${response.status} ${response.statusText}`);
			}

			message.success("Post Created.");
			navigate("/blogs");

			setTitle("");
			setContent("");
			setThumbnail(null);
			setPreviewUrl("");
		} catch (error) {
			console.error("Error submitting the form:", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="blog-post-form-container">
			<button className="close-btn" onClick={() => navigate("/blogs")}>
				X
			</button>
			<h1>Create New Blog Post</h1>
			<form onSubmit={handleSubmit} className="blog-post-form">
				<div className="form-group">
					<label htmlFor="title">Title</label>
					<input
						type="text"
						id="title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						placeholder="Enter post title"
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="thumbnail">Thumbnail</label>
					<div className="thumbnail-input-container">
						<input
							type="file"
							id="thumbnail"
							accept="image/*"
							onChange={handleThumbnailChange}
							className="thumbnail-input"
						/>
						{previewUrl && (
							<div className="thumbnail-preview">
								<img src={previewUrl} alt="Thumbnail preview" />
							</div>
						)}
					</div>
				</div>

				<div className="form-group">
					<label>Content</label>
					<ReactQuill
						value={content}
						onChange={setContent}
						modules={modules}
						className="quill-editor"
						placeholder="Write your blog post content here..."
					/>
				</div>

				<Button
					type="primary"
					htmlType="submit"
					loading={isLoading}
					className="submit-button">
					{isLoading ? "Publishing..." : "Publish Post"}
				</Button>
			</form>
		</div>
	);
}

export default BlogPostForm;

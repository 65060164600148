import { Button, Form, Input, message, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import "./EditProduct.css";

const { Option } = Select;
const EditProduct = ({ product, onClose }) => {
	const { apiurl, token } = useAuth();

	const [prodDetails, setprodDetails] = useState(product);
	const [mainCategories, setMainCategories] = useState([]);
	const [categories, setCategories] = useState([]);
	const [filteredCategories, setFilteredCategories] = useState([]);
	const [filteredSubcategories, setFilteredSubcategories] = useState([]);
	const [subcategories, setSubcategories] = useState([]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setprodDetails((prev) => ({ ...prev, [name]: value }));
	};

	const handleSelectChange = (value, name) => {
		setprodDetails((prev) => ({ ...prev, [name]: value }));
	};

	const handleSave = async () => {
		const response = await fetch(`${apiurl}/products/${prodDetails.id}/`, {
			method: "PUT",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(prodDetails),
		});
		if (response.ok) {
			message.success("Product updated successfully");
			onClose();
		} else {
			message.error("Failed to update product");
		}
	};

	useEffect(() => {
		const fetchData = async (endpoint, setter) => {
			try {
				const response = await fetch(`${apiurl}${endpoint}`, {
					method: "GET",
					headers: { Authorization: `Bearer ${token}` },
				});
				const data = await response.json();
				if (response.ok) {
					setter(data);
				} else {
					console.error(`Failed to fetch ${endpoint.slice(1)}`);
				}
			} catch (error) {
				console.error(`Error fetching ${endpoint.slice(1)}:`, error);
			} finally {
			}
		};

		fetchData("/maincategories", setMainCategories);
		fetchData("/categories", setCategories);
		fetchData("/subcategories", setSubcategories);
	}, [apiurl, token]);

	useEffect(() => {
		const filtered = categories.filter(
			(cat) => cat.id === prodDetails.mainCategory
		);
		const filteredSub = subcategories.filter(
			(sub) => sub.id === prodDetails.category
		);
		setFilteredCategories(filtered);
		setFilteredSubcategories(filteredSub);
	}, [mainCategories, categories, subcategories]);

	return (
		<div className="edit-form">
			<Form layout="vertical" className="general-info-form">
				<Form.Item label="Product Name" required className="form-item">
					<Input
						name="name"
						value={prodDetails.name}
						onChange={handleInputChange}
						className="form-input"
					/>
				</Form.Item>
				<div className="form-item-group">
					<Form.Item label="Main Category" required className="form-item">
						<Select
							value={prodDetails.mainCategory}
							onChange={(value) => {
								handleSelectChange(value, "mainCategory");
								handleSelectChange(null, "category");
								handleSelectChange(null, "subcategory");
								const filtered = categories.filter((cat) => cat.id === value);
								setFilteredCategories(filtered);
							}}
							className="form-select">
							{mainCategories.map((cat) => (
								<Option key={cat.id} value={cat.id} className="form-option">
									{cat.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label="Category" required className="form-item">
						<Select
							value={prodDetails.category}
							onChange={(value) => {
								handleSelectChange(value, "category");
								handleSelectChange(null, "subcategory");
								const filtered = subcategories.filter(
									(sub) => sub.id === value
								);
								setFilteredSubcategories(filtered);
							}}
							className="form-select">
							{filteredCategories.map((cat) => (
								<Option key={cat.id} value={cat.id} className="form-option">
									{cat.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label="Subcategory" required className="form-item">
						<Select
							value={prodDetails.subcategory}
							onChange={(value) => handleSelectChange(value, "subcategory")}
							className="form-select">
							{filteredSubcategories.map((cat) => (
								<Option key={cat.id} value={cat.id} className="form-option">
									{cat.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</div>
				<Form.Item label="Description" required className="form-item">
					<TextArea
						name="description"
						value={prodDetails.description}
						onChange={handleInputChange}
						className="form-textarea"
					/>
				</Form.Item>
				<Button onClick={handleSave} className="form-button">
					Save
				</Button>
			</Form>
		</div>
	);
};

export default EditProduct;

import React, { useState, useEffect } from "react";
import Main from "./Layout";
import { useAuth } from "../utils/useAuth";
import "./Categories.css";
import { Card, message, Modal, Input, Select, Button } from "antd";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const AdminCategories = () => {
	const { apiurl, token } = useAuth();
	const [categories, setCategories] = useState([]);
	const [mainCategories, setMainCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formData, setFormData] = useState({
		categoryName: "",
		thumbnail: null,
		mainCategory: "",
	});
	const navigate = useNavigate();

	const fetchCategories = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/admin/categories`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				setLoading(false);
				message.error("Failed to fetch categories");
				return;
			}

			const data = await response.json();
			setCategories(data);
		} catch (error) {
			message.error("Network Error! Please Try Again.");
			console.error("Error fetching categories:", error);
		} finally {
			setLoading(false);
		}
	};

	const fetchMainCategories = async () => {
		try {
			const response = await fetch(`${apiurl}/maincategories`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				message.error("Failed to fetch main categories");
				return;
			}

			const data = await response.json();
			setMainCategories(data);
		} catch (error) {
			message.error("Network Error! Please Try Again.");
			console.error("Error fetching main categories:", error);
		}
	};

	useEffect(() => {
		fetchCategories();
		fetchMainCategories();
	}, [apiurl, token]);

	const handleAddCategory = () => {
		setIsModalOpen(true);
	};

	const handleModalOk = async () => {
		if (
			!formData.categoryName ||
			!formData.mainCategory ||
			!formData.thumbnail
		) {
			message.error("All fields are required.");
			return;
		}

		const formDataToSend = new FormData();
		formDataToSend.append("name", formData.categoryName);
		formDataToSend.append("thumbnail", formData.thumbnail);
		formDataToSend.append("main_category_id", formData.mainCategory);

		try {
			const response = await fetch(`${apiurl}/categories/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSend,
			});

			if (!response.ok) {
				message.error("Failed to add category.");
				return;
			}

			message.success("Category added successfully.");
			setIsModalOpen(false);
			fetchCategories();
			setFormData({ categoryName: "", thumbnail: null, mainCategory: "" });

			const updatedCategories = await response.json();
			setCategories((prev) => [...prev, updatedCategories]);
		} catch (error) {
			message.error("Network Error! Please Try Again.");
			console.error("Error adding category:", error);
		}
	};

	const handleModalCancel = () => {
		setIsModalOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleFileChange = (e) => {
		setFormData((prev) => ({ ...prev, thumbnail: e.target.files[0] }));
	};

	const handleSelectChange = (value) => {
		setFormData((prev) => ({ ...prev, mainCategory: value }));
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<Main>
			<div className="admin-categories-container">
				<div className="add-btn-cont">
					<button onClick={handleAddCategory}>+ Add New Category</button>
				</div>
				{categories.length > 0 ? (
					<div className="grid-container">
						{categories.map(
							(category, index) =>
								category && (
									<div
										key={index}
										className="category-item"
										onClick={() =>
											navigate(`/admin/categories/${category.category.id}`)
										}>
										<img
											src={apiurl + category.category.thumbnail}
											alt={category.category.name}
											className="category-image"
										/>
										<div className="cat-meta">
											<h4 className="sub-category">
												{category.category.maincategory.name} -{" "}
												{category.category.name}
											</h4>

											<p className="item-count">
												{category.count}{" "}
												{category.count === 1 ? "Item" : "Items"}
											</p>
										</div>
									</div>
								)
						)}
					</div>
				) : (
					<p className="no-categories">No categories found.</p>
				)}

				{}
				<Modal
					title="Add New Category"
					open={isModalOpen}
					onOk={handleModalOk}
					onCancel={handleModalCancel}>
					<Input
						name="categoryName"
						placeholder="Category Name"
						value={formData.categoryName}
						onChange={handleInputChange}
						style={{ marginBottom: "1rem" }}
					/>
					<input
						type="file"
						accept="image/*"
						onChange={handleFileChange}
						style={{ marginBottom: "1rem" }}
					/>
					<Select
						placeholder="Select Main Category"
						value={formData.mainCategory}
						onChange={handleSelectChange}
						style={{ width: "100%" }}>
						{mainCategories.map((mainCategory) => (
							<Option key={mainCategory.id} value={mainCategory.id}>
								{mainCategory.name}
							</Option>
						))}
					</Select>
				</Modal>
			</div>
		</Main>
	);
};

export default AdminCategories;

import React from "react";
import Main from "./Layout";

const AdminReturns = () => {
	return (
		<Main>
			<div>Return</div>
		</Main>
	);
};

export default AdminReturns;

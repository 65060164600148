import React, { useState } from "react";
import { useAuth } from "../utils/useAuth";
import "./AddAddressForm.css";
import { message } from "antd";

const EditAddressForm = ({ addressobj }) => {
	const { apiurl, token } = useAuth();

	const [city, setCity] = useState(addressobj.city);
	const [contactName, setContactName] = useState(addressobj.contact_name);
	const [contactNumber, setContactNumber] = useState(addressobj.contact_number);
	const [state, setState] = useState(addressobj.state);
	const [pincode, setPincode] = useState(addressobj.pincode);
	const [address, setAddress] = useState(addressobj.address);
	const [country, setCountry] = useState(addressobj.country);
	const [addressType, setAddressType] = useState(addressobj.address_type);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const addressData = {
			city,
			contact_name: contactName,
			contact_number: contactNumber,
			state,
			country,
			pincode,
			address,
			address_type: addressType,
		};

		try {
			const response = await fetch(`${apiurl}/address/?id=${addressobj.id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(addressData),
			});

			const result = await response.json();

			if (response.ok) {
				message.success(result.message);
			} else {
				message.error(result.error || "Something went wrong!");
			}
		} catch (error) {
			message.error("Failed to update address. Please try again later.");
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="add-address-form-container">
			<form onSubmit={handleSubmit}>
				<div className="form-group">
					<label>City</label>
					<input
						type="text"
						value={city}
						onChange={(e) => setCity(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Contact Name</label>
					<input
						type="text"
						value={contactName}
						onChange={(e) => setContactName(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Contact Number</label>
					<input
						type="text"
						value={contactNumber}
						onChange={(e) => setContactNumber(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Country</label>
					<input
						type="text"
						value={country}
						onChange={(e) => setCountry(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>State</label>
					<input
						type="text"
						value={state}
						onChange={(e) => setState(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Pincode</label>
					<input
						type="text"
						value={pincode}
						onChange={(e) => setPincode(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Address</label>
					<textarea
						value={address}
						onChange={(e) => setAddress(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Type</label>
					<select
						value={addressType}
						onChange={(e) => setAddressType(e.target.value)}
						required>
						<option value="">Select Type</option>
						<option value="home">Home</option>
						<option value="office">Office</option>
						<option value="school">School</option>
						<option value="other">Other</option>
					</select>
				</div>
				<button type="submit" disabled={loading}>
					{loading ? "Updating Address..." : "Update Address"}
				</button>
			</form>

			{loading && (
				<div className="loader-container">
					<div className="loader"></div>
				</div>
			)}
		</div>
	);
};

export default EditAddressForm;

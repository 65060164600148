import React from "react";
import "./CartIcon.css";
import carticon from "./../../images/Products/bag.png";
import { Badge } from "antd";
import { useNavigate } from "react-router-dom";

const CartIcon = ({ count }) => {
	const navigate = useNavigate();
	return (
		<div className="floating-cart-icon" onClick={() => navigate("/cart")}>
			<Badge showZero count={count} overflowCount={9} color="#fff">
				<img src={carticon} alt="carticon"></img>
			</Badge>
		</div>
	);
};

export default CartIcon;

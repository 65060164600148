import React, { useState, useEffect } from "react";
import {
	Table,
	Button,
	Form,
	Input,
	Radio,
	Checkbox,
	DatePicker,
	Select,
	message,
	Tabs,
} from "antd";
import {
	EyeOutlined,
	EditOutlined,
	DeleteOutlined,
	FilterOutlined,
	DollarOutlined,
	PercentageOutlined,
	BackwardOutlined,
	ArrowLeftOutlined,
} from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import Main from "./Layout";
import moment from "moment";
import "./Coupons.css";
import Loader from "../Loader/Loader";

const { Option } = Select;
const { TabPane } = Tabs;

const AdminCoupons = () => {
	const [coupons, setCoupons] = useState([]);
	const [products, setProducts] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [showCreateForm, setShowCreateForm] = useState(false);
	const [couponData, setCouponData] = useState({
		code: "",
		name: "",
		type: "Fixed Discount",
		value: "",
		appliesTo: [],
		startDate: null,
		endDate: null,
		usageLimit: "",
		noDurationLimit: false,
		noUsageLimit: false,
	});
	const { token, apiurl } = useAuth();

	const fetchCoupons = async () => {
		setIsLoading(true);
		setError(null);

		try {
			const response = await fetch(`${apiurl}/coupons`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});

			if (!response.ok) {
				throw new Error("Failed to fetch coupons");
			}

			const data = await response.json();
			setCoupons(data);
		} catch (err) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchProducts = async () => {
		try {
			const response = await fetch(`${apiurl}/products`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});

			if (!response.ok) {
				throw new Error("Failed to fetch products");
			}

			const data = await response.json();
			setProducts(data);
		} catch (err) {
			setError(err.message);
		}
	};
	useEffect(() => {
		fetchCoupons();
		fetchProducts();
	}, [apiurl, token]);

	const handleCreateClick = () => {
		setShowCreateForm(true);
	};

	const handleCancelCreate = () => {
		setShowCreateForm(false);
	};

	const handleChange = (name, value) => {
		setCouponData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSaveCoupon = async () => {
		const postData = {
			...couponData,
			start_date: couponData.startDate ? couponData.startDate.format() : null,
			end_date: couponData.endDate ? couponData.endDate.format() : null,
		};

		try {
			const response = await fetch(`${apiurl}/coupons`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(postData),
			});

			if (!response.ok) {
				message.error("Failed to save coupon");
			}

			fetchCoupons();
			setCouponData({
				code: "",
				name: "",
				type: "Fixed Discount",
				value: "",
				appliesTo: [],
				startDate: null,
				endDate: null,
				usageLimit: "",
				noDurationLimit: false,
				noUsageLimit: false,
			});
			setShowCreateForm(false);
		} catch (err) {
			message.error(err.message);
		}
	};

	const columns = [
		{
			title: "Coupon Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Usage",
			dataIndex: "usage_limit",
			key: "usage_limit",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Start Date",
			dataIndex: "start_date",
			key: "start_date",
			render: (startDate) => moment(startDate).format("YYYY-MM-DD"),
		},
		{
			title: "End Date",
			dataIndex: "end_date",
			key: "end_date",
			render: (endDate) => moment(endDate).format("YYYY-MM-DD"),
		},
	];

	const filterCouponsByStatus = (status) => {
		if (status === "active") {
			return coupons.filter(
				(coupon) =>
					moment(coupon.start_date).isBefore(moment()) &&
					moment(coupon.end_date).isAfter(moment())
			);
		}
		if (status === "expired") {
			return coupons.filter((coupon) =>
				moment(coupon.end_date).isBefore(moment())
			);
		}
		return coupons;
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Main>
			<div className="vendor-coupon-container">
				{showCreateForm ? (
					<>
						<button
							className="back-coupon-btn"
							onClick={() => setShowCreateForm(false)}>
							<ArrowLeftOutlined /> Go Back
						</button>
						<Form
							layout="vertical"
							onFinish={handleSaveCoupon}
							className="coupon-form">
							<div className="form-section">
								<div className="coupon-header">
									<div>
										<h2>Coupon Information</h2>
										<p>Code will be used by users in checkout</p>
									</div>
									<div className="form-actions">
										<Button
											type="default"
											onClick={handleCancelCreate}
											className="cancel-button">
											Cancel
										</Button>
										<Button
											type="primary"
											htmlType="submit"
											className="save-button">
											Save
										</Button>
									</div>
								</div>
								<div className="form-group">
									<Form.Item label="Coupon Code">
										<Input
											value={couponData.code}
											onChange={(e) => handleChange("code", e.target.value)}
										/>
									</Form.Item>

									<Form.Item label="Coupon Name">
										<Input
											value={couponData.name}
											onChange={(e) => handleChange("name", e.target.value)}
										/>
									</Form.Item>
								</div>
							</div>

							<div className="form-section">
								<h2>Coupon Type</h2>
								<p>Type of coupon you want to create</p>
								<Radio.Group
									value={couponData.type}
									onChange={(e) => handleChange("type", e.target.value)}
									className="coupon-type-radio">
									<Radio.Button
										key={"Fixed Discount"}
										value={"Fixed Discount"}
										className="coupon-type-option">
										<DollarOutlined />
										Price Discount
									</Radio.Button>
									<Radio.Button
										key={"Percentage Discount"}
										value={"Percentage Discount"}
										className="coupon-type-option">
										<PercentageOutlined />
										Percentage Discount
									</Radio.Button>
								</Radio.Group>
							</div>

							<div className="form-section">
								<div className="form-group">
									<Form.Item label="Discount Value">
										<Input
											value={couponData.value}
											onChange={(e) => handleChange("value", e.target.value)}
										/>
									</Form.Item>

									<Form.Item label="Applies To">
										<Select
											mode="multiple"
											value={couponData.appliesTo}
											onChange={(value) => handleChange("appliesTo", value)}>
											{products.map((product) => (
												<Option key={product.id} value={product.id}>
													{product.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								</div>

								<div className="form-group">
									<Form.Item label="Start Date">
										<DatePicker
											value={couponData.startDate}
											onChange={(date) => handleChange("startDate", date)}
										/>
									</Form.Item>

									<Form.Item label="End Date">
										<DatePicker
											value={couponData.endDate}
											onChange={(date) => handleChange("endDate", date)}
										/>
									</Form.Item>
								</div>

								<Form.Item>
									<Checkbox
										checked={couponData.noDurationLimit}
										onChange={(e) =>
											handleChange("noDurationLimit", e.target.checked)
										}>
										Don't set duration
									</Checkbox>
								</Form.Item>
							</div>

							<div className="form-section">
								<h2>Usage Limits</h2>
								<div className="form-group">
									<Form.Item label="Usage Limit">
										<Input
											value={couponData.usageLimit}
											onChange={(e) =>
												handleChange("usageLimit", e.target.value)
											}
											disabled={couponData.noUsageLimit}
										/>
									</Form.Item>
								</div>

								<Checkbox
									checked={couponData.noUsageLimit}
									onChange={(e) =>
										handleChange("noUsageLimit", e.target.checked)
									}>
									Don't limit amount of uses
								</Checkbox>
							</div>
						</Form>
					</>
				) : (
					<div className="table-container">
						<button className="create-coupon-btn" onClick={handleCreateClick}>
							+ Create Coupon
						</button>
						<Tabs defaultActiveKey="1">
							<TabPane tab="All Coupons" key="1">
								<Table
									dataSource={filterCouponsByStatus("all")}
									columns={columns}
									rowKey={(record) => record.id}
									pagination={{ pageSize: 10 }}
								/>
							</TabPane>
							<TabPane tab="Active Coupons" key="2">
								<Table
									dataSource={filterCouponsByStatus("active")}
									columns={columns}
									rowKey={(record) => record.id}
									pagination={{ pageSize: 10 }}
								/>
							</TabPane>
							<TabPane tab="Expired Coupons" key="3">
								<Table
									dataSource={filterCouponsByStatus("expired")}
									columns={columns}
									rowKey={(record) => record.id}
									pagination={{ pageSize: 10 }}
								/>
							</TabPane>
						</Tabs>
					</div>
				)}
			</div>
		</Main>
	);
};

export default AdminCoupons;

import React, { useDebugValue, useEffect, useState } from "react";
import {
	HeartOutlined,
	HeartFilled,
	ShoppingCartOutlined,
} from "@ant-design/icons";
import "./Productcard.css";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/useAuth";

const ProductCard = ({ product, toogleWishlist}) => {
	const { apiurl, user , currentCountry } = useAuth();
	const [price, setPrice] = useState(0);
	const [discountedPrice, setDiscountedPrice] = useState(0);

	useEffect(() => {
		setDiscountedPrice(product.discounted_price || product.price);
		const countryName = currentCountry.name;

		const price = product.country_specific_prices.find(
			(price) => price.country_name === countryName
		);
		const countryPrice = price ? price.price : product.price;

		setPrice(countryPrice);

	}, [product,currentCountry]);

	const handleWishlistClick = () => {
		toogleWishlist(product.id, !product.is_wishlisted);
	};

	return (
		<div className="product-card">
			<div className="image-container">
				<Link to={`/products/${product.product_id}`}>
					<img
						src={
							product.images &&
							product.images[0] &&
							apiurl + product.images[0].image
						}
						alt={product.product_name}
						className="product-image"
					/>
				</Link>
				<button className="wishlist-icon" onClick={handleWishlistClick}>
					{product.is_wishlisted ? (
						<HeartFilled style={{ color: "red", fontSize: "16px" }} />
					) : (
						<HeartOutlined style={{ color: "white", fontSize: "16px" }} />
					)}
				</button>
			</div>
			<Link to={`/products/${product.product_id}`}>
				<div className="product-details">
					<h3 className="product-name">
						{product.product_name.length > 20
							? product.product_name.slice(0, 20) + "..."
							: product.product_name}
					</h3>
					<div className="product-pricing">
						<span className="current-price">
							{currentCountry.symbol} {price}
						</span>
						{/* {discountedPrice < price && (
							<>
								<span className="original-price">
									{currentCountry.symbol} {price}
								</span>
								<span className="discount">
									-{Math.round(((price - discountedPrice) / price) * 100)}%
								</span>
							</>
						)} */}
					</div>
				</div>
			</Link>
		</div>
	);
};

export default ProductCard;

import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import { Button, message, Modal, Popconfirm } from "antd";
import AddAddressForm from "../user/AddAddressForm";
import "./AddressTab.css";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EditAddressForm from "../user/EditAddressForm";

const AddressTab = () => {
	const [address, setAddress] = useState([]);
	const { apiurl, token } = useAuth();
	const [addAddressModalVisible, setAddAddressModalVisible] = useState(false);
	const [editAddressModalVisible, setEditAddressModalVisible] = useState(false);
	const [selectedAddress, setSelectedAddress] = useState(null);

	useEffect(() => {
		fetchAddress();
	}, []);

	const fetchAddress = async () => {
		const response = await fetch(`${apiurl}/address/`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
		if (response.ok) {
			const data = await response.json();
			setAddress(data.data);
		} else {
			message.error("Failed to fetch address");
		}
	};

	const handleEdit = (addressObj) => {
		setSelectedAddress(addressObj);
		setEditAddressModalVisible(true);
	};

	const handleDelete = async (addressId) => {
		const response = await fetch(`${apiurl}/address/?id=${addressId}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
		if (response.ok) {
			message.success("Address deleted successfully");
			fetchAddress();
		} else {
			message.error("Failed to delete address");
		}
	};

	return (
		<div className="profile-address">
			<div className="addr-header">
				<h3>Manage Addresses</h3>
				<button onClick={() => setAddAddressModalVisible(true)}>
					+ Add New Address
				</button>
			</div>
			{address.length > 0 &&
				address.map((addr) => (
					<div key={addr.id} className="address-item">
						<div className="address-details">
							<div className="address-header">
								<div className="type-tag">{addr.address_type}</div>
								<div className="address-actions">
									<Button
										type="default"
										className="edit-btn"
										onClick={() => handleEdit(addr)}>
										<EditOutlined />
									</Button>
									<Popconfirm
										title="Are you sure you want to delete this address?"
										onConfirm={() => handleDelete(addr.id)}
										okText="Yes"
										cancelText="No">
										<Button danger className="delete-btn">
											<DeleteOutlined />
										</Button>
									</Popconfirm>
								</div>
							</div>
							<div className="name">
								{addr.contact_name} | {addr.contact_number}
							</div>

							<p className="address">
								{addr.address}, {addr.city}, {addr.country} - {addr.pincode}
							</p>
						</div>
					</div>
				))}
			<Modal
				title="Add a New Address"
				open={addAddressModalVisible}
				onCancel={() => {
					setAddAddressModalVisible(false);
					fetchAddress();
				}}
				footer={null}>
				<AddAddressForm />
			</Modal>
			<Modal
				title="Edit Address"
				open={editAddressModalVisible}
				onCancel={() => {
					setEditAddressModalVisible(false);
					fetchAddress();
				}}
				footer={null}>
				{selectedAddress && <EditAddressForm addressobj={selectedAddress} />}
			</Modal>
		</div>
	);
};

export default AddressTab;

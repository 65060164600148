import React, { useEffect, useState } from "react";
import "./SpecificBlog.css";
import { useAuth } from "../utils/useAuth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { Breadcrumb, message } from "antd";
import Loader from "../Loader/Loader";
import BlogPost from "../Blogs/BlogPost";
import Main from "../user/Layout";

const SpecificBlog = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { apiurl } = useAuth();
	const [relatedPosts, setRelatedPosts] = useState([]);
	const [singleBlogPost, setSingleBlogPost] = useState();

	const [loading, setLoading] = useState();

	useEffect(() => {
		fetchBlogPosts();
		fetchBlogPostById();
	}, [id]);

	const fetchBlogPosts = async () => {
		setLoading(true);

		try {
			const response = await fetch(`${apiurl}/blogposts/`, {
				method: "GET",
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.status} ${response.statusText}`);
			}
			setLoading(false);
			const data = await response.json();
			setRelatedPosts(data);
		} catch (err) {
			message.error("Error fetching blog posts: ");
		} finally {
			setLoading(false);
		}
	};

	const fetchBlogPostById = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/blogposts/${id}/`);
			setLoading(false);
			const data = await response.json();
			setSingleBlogPost(data);
		} catch (err) {
			message.error("Error fetching blog posts: ");
		} finally {
			setLoading(false);
		}
	};

	console.log(loading);
	if (loading) {
		return <Loader />;
	}

	const formatDate = (date) => {
		const options = { year: "numeric", month: "long", day: "numeric" };
		return new Date(date).toLocaleDateString("en-US", options);
	};

	return (
		<Main>
			<div className="sp-blog-container">
				<div className="breadcrumb-container">
					<Breadcrumb>
						<Breadcrumb.Item>
							<Link to="/">Home</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<div
								style={{ cursor: "pointer" }}
								onClick={() => navigate("/blogs")}>
								Blogs
							</div>
						</Breadcrumb.Item>
						<Breadcrumb.Item>{singleBlogPost?.title}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div className="sp-blog-header">
					<h1 className="blog-title">{singleBlogPost?.title}</h1>
					<div className="blog-meta-info">
						<p>
							<UserOutlined /> {singleBlogPost?.user?.username}
						</p>
						<p>
							<FieldTimeOutlined /> {formatDate(singleBlogPost?.created_at)}
						</p>
					</div>
				</div>

				<div className="sp-blog-img">
					<img
						src={`${apiurl}${singleBlogPost?.thumbnail}`}
						alt={singleBlogPost?.title}
						className="featured-image"
					/>
				</div>

				<div className="sp-blog-content">
					<div
						className="sp-blog-post-description"
						dangerouslySetInnerHTML={{ __html: singleBlogPost?.content }}></div>
				</div>

				<div className="sp-related-section">
					<div className="sp-related-header">
						<h2 className="related-title">You might also like</h2>
						<Link to="/blogs" className="more-link">
							More Articles →
						</Link>
					</div>

					<div className="sp-related-posts">
						{relatedPosts.slice(0, 4).map((post, index) => (
							<Link key={index} to={`/blogs/${post.id}`}>
								<BlogPost {...post} />
							</Link>
						))}
					</div>
				</div>
			</div>
		</Main>
	);
};

export default SpecificBlog;

import React from "react";
import Main from "./Layout";

const AdminAccount = () => {
	return (
		<Main>
			<div>Profile</div>
		</Main>
	);
};

export default AdminAccount;

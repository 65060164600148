import React, { useEffect, useState } from "react";
import Main from "./Layout";
import "./Men.css";
import banner from "./../../images/womens/banner.png";
import indianwear from "./../../images/womens/indianwear.png";
import westernwear from "./../../images/womens/westernwear.png";
import sleepwear from "./../../images/womens/sleepwear.png";
import sportswear from "./../../images/womens/sportswear.png";
import plussize from "./../../images/womens/plussize.png";
import meternitywear from "./../../images/womens/meternitywear.png";
import footwear from "./../../images/womens/footwear.png";
import beauty from "./../../images/womens/beauty.png";
import bestsellings from "./../../images/womens/bestsellings.png";
import newarrivals from "./../../images/womens/newarrivals.png";
import clearance from "./../../images/womens/clearance.png";
import toprated from "./../../images/womens/toprated.png";



import section4banner from "./../../images/Mens/section4.png";
import faqimg from "./../../images/Mens/FAQ.png";
import fire from "./../../images/Mens/fire.png";
import secured from "./../../images/Mens/secure.png";
import returns from "./../../images/Mens/return.png";
import shipping from "./../../images/Mens/fastshipping.png";


import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import ProductCard from "./Productcard";
import { message } from "antd";

const Women = () => {
  const navigate = useNavigate();
  const [topProducts, setTopProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const { apiurl, token } = useAuth();

  useEffect(() => {
    fetchNewArrivals();
  }, [token]);

  const fetchNewArrivals = async () => {
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(
      `${apiurl}/products/newarrivals?category=WOMEN`,
      {
        headers: headers,
      }
    );
    const data = await response.json();
    setTopProducts(data);
  };

  const toogleWishlist = async (prodid, status) => {
    if (!token) {
      message.warning("Please login to add to wishlist");
      return;
    }
    let method = status === true ? "POST" : "DELETE";
    const response = await fetch(`${apiurl}/wishlist/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: method,
      body: JSON.stringify({ item_id: prodid }),
    });
    if (response.ok) {
      fetchNewArrivals();
      message.success("Added to wishlist");
    } else {
      console.log("Error");
    }
  };


  useEffect(() => {
      fetchCategories();
    }, []);
  
    const fetchCategories = async () => {
      const response = await fetch(`${apiurl}/topcategories?cat=WOMEN`);
      const data = await response.json();
      if (response.ok) {
        setCategories(data);
      } else {
        setCategories([]);
      }
    };
  return (
		<Main>
			<div className="mens-main">
				<div className="section-1">
					<img src={banner} alt="SHEagle"></img>
					<div className="banner-content">
						<h3>TRENDING FASHION ITEMS TO</h3>
						<h1>
							<span>UNLEASH</span> YOUR <br />
							INNER <span>ELEGANCE</span>
						</h1>
						<button onClick={() => navigate("/products?mainCategories=3")}>
							Shop Now
						</button>
					</div>
					<div className="end-blocks">
						<div className="block-1">
							<h1>
								DRESS SHARP, <br />
								LIVE SMART
							</h1>
						</div>
						<div className="right-blocks">
							<div className="block">
								<img src={fire} alt="sheagle"></img>
								<h4>Trend-Forward Collections</h4>
								<p>Stay fashionable with our latest styles </p>
							</div>
							<div className="block">
								<img src={shipping} alt="sheagle"></img>
								<h4>Fast Shipping</h4>
								<p>Get your new clothes quickly with our speedy shipping</p>
							</div>
							<div className="block">
								<img src={returns} alt="sheagle"></img>
								<h4>Easy Returns and Exchanges</h4>
								<p>If it doesn't fit or you don't like it, return it easily</p>
							</div>
							<div className="block">
								<img src={secured} alt="sheagle"></img>
								<h4>Secured Payments</h4>
								<p>Pay with our safe and secure payment options</p>
							</div>
						</div>
					</div>
				</div>
				<div className="section-2">
					<div className="row-1">
						{categories.length > 0 &&
							categories.map((category) => (
								<div className="cat-item">
									<img
										src={apiurl + category.thumbnail}
										alt="sheEagle-topwear"
									/>
									<p>{category.name}</p>
									<button
										onClick={() =>
											navigate(
												`/products?mainCategories=${category.maincategory.id}&categories=${category.id}`
											)
										}>
										Shop Now
									</button>
								</div>
							))}
					</div>
				</div>
				<div className="section-3">
					<div className="section-item">
						<h1>New Arrivals</h1>
						<p>Shop the latest fashion trends from the top luxury designers</p>
						<button
							className="shopnowbtn"
							onClick={() => navigate("/products?mainCategories=3")}>
							Shop Now
						</button>
					</div>
					{topProducts &&
						topProducts.map((product, index) => (
							<div className="section-item">
								<ProductCard
									toogleWishlist={toogleWishlist}
									key={index}
									product={product.product_varients[0]}></ProductCard>
							</div>
						))}
				</div>
				<div className="section-4">
					<img src={section4banner} alt="sheagle"></img>
					<div>
						<h2>
							Empovering your style with elegant, <br />
							<span>VERSATILE FASHION CHOICES</span>
						</h2>
						<button onClick={() => navigate("/products?mainCategories=3")}>
							Shop Now
						</button>
					</div>
				</div>
				<div className="section-5">
					<div className="row-1">
						<div>
							<img src={bestsellings} alt="sheEagle-topwear" />
							<p>BEST SELLINGS</p>
							<button
								onClick={() =>
									navigate("/products/?mainCategories=3&sort=best_sellings")
								}>
								Shop Now
							</button>
						</div>
						<div>
							<img src={newarrivals} alt="sheEagle-festivewear" />
							<p>NEW ARRIVALS</p>
							<button
								onClick={() =>
									navigate("/products/?mainCategories=3&sort=new_arrivals")
								}>
								Shop Now
							</button>
						</div>
						<div>
							<img src={clearance} alt="sheEagle-bottomwear" />
							<p>MOST WISHED</p>
							<button
								onClick={() =>
									navigate("/products/?mainCategories=3&sort=most_wished")
								}>
								Shop Now
							</button>
						</div>
						<div>
							<img src={toprated} alt="sheEagle-sleepwear" />
							<p>TOP RATED</p>
							<button
								onClick={() =>
									navigate("/products/?mainCategories=3&sort=top_rated")
								}>
								Shop Now
							</button>
						</div>
					</div>
				</div>
			</div>
		</Main>
	);
};

export default Women;

import React, { useState, useEffect } from "react";
import { Table, Tabs, Typography, Tag } from "antd";
import "./Wallet.css";
import { useAuth } from "../utils/useAuth";
import {
	MenuOutlined,
	UserOutlined,
	ShoppingCartOutlined,
	HeartOutlined,
	SearchOutlined,
	WalletOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
} from "@ant-design/icons";

const { TabPane } = Tabs;

const Wallet = () => {
	const { apiurl, token, currentCountry } = useAuth();
	const [transactions, setTransactions] = useState([]);
	const [balances, setBalances] = useState({
		total: 0,
		credited: 0,
		debited: 0,
	});
	const [filteredTransactions, setFilteredTransactions] = useState([]);

	useEffect(() => {
		const fetchTransactions = async () => {
			try {
				const response = await fetch(
					`{currentCountry.symbol}{apiurl}/mywallet`,
					{
						method: "GET",
						headers: {
							Authorization: `Bearer {currentCountry.symbol}{token}`,
							"Content-Type": "application/json",
						},
					}
				);
				const data = await response.json();
				setTransactions(data.transactions);
				setFilteredTransactions(data.transactions);
				setBalances(data.balances);
			} catch (error) {
				console.error("Error fetching transactions:", error);
			}
		};

		fetchTransactions();
	}, [apiurl, token]);

	const columns = [
		{
			title: "Transaction Date",
			dataIndex: "created_at",
			key: "created_at",
		},
		{
			title: "For",
			dataIndex: "for",
			key: "for",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (type) => (
				<Tag color={type === "Credited" ? "green" : "red"}>{type}</Tag>
			),
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
			render: (amount, record) => (
				<span style={{ color: record.type === "Credited" ? "green" : "red" }}>
					{record.type === "Credited" ? "+" : "-"}
					{currentCountry.symbol}
					{amount.toFixed(2)}
				</span>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status) => (
				<Tag color={status === "Completed" ? "blue" : "orange"}>{status}</Tag>
			),
		},
	];

	const handleTabChange = (key) => {
		if (key === "All") {
			setFilteredTransactions(transactions);
		} else {
			setFilteredTransactions(
				transactions.filter((txn) => txn.type === key || txn.status === key)
			);
		}
	};

	return (
		<div className="wallet-transactions">
			<div className="balances">
				<div className="balance-item">
					<WalletOutlined />
					<div>
						<p>Balances:</p>{" "}
						<p>
							{currentCountry.symbol}
							{balances.total.toFixed(2)}
						</p>
					</div>
				</div>

				<div className="balance-item">
					<ArrowUpOutlined />
					<div>
						<p>Credited:</p>{" "}
						<p>
							{currentCountry.symbol}
							{balances.credited.toFixed(2)}
						</p>
					</div>
				</div>
				<div className="balance-item">
					<ArrowDownOutlined />
					<div>
						<p>Debited:</p>
						<p>
							{currentCountry.symbol}
							{balances.debited.toFixed(2)}
						</p>
					</div>
				</div>
			</div>
			<Tabs defaultActiveKey="All" onChange={handleTabChange}>
				<TabPane tab="All" key="All"></TabPane>
				<TabPane tab="Credited" key="Credited"></TabPane>
				<TabPane tab="Debited" key="Debited"></TabPane>
				<TabPane tab="Failed" key="Failed"></TabPane>
			</Tabs>
			<Table
				columns={columns}
				dataSource={filteredTransactions}
				rowKey="refId"
				pagination={{ pageSize: 5 }}
			/>
		</div>
	);
};

export default Wallet;

import React, { useState, useEffect } from "react";
import Main from "./Layout";
import { useAuth } from "../utils/useAuth";
import "./Categories.css"; // Assuming a custom CSS file for additional styles
import { Card, Input, message, Modal } from "antd";
import Loader from "../Loader/Loader";

const Categories = () => {
	const { apiurl, token } = useAuth();
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formData, setFormData] = useState({
		categoryName: "",
	});

	useEffect(() => {
		const fetchCategories = async () => {
			setLoading(true);
			try {
				const response = await fetch(`${apiurl}/mycategories/vendor/`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (!response.ok) {
					setLoading(false);
					message.error("Failed to fetch categories");
				}

				const data = await response.json();
				setCategories(data);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				message.error("Network Error! Please Try Again.");
				console.error("Error fetching categories:", error);
			}
		};

		fetchCategories();
	}, [apiurl, token]);

	const handleAddCategory = () => {
		setIsModalOpen(true);
	};

	const handleModalOk = async () => {
		if (!formData.categoryName) {
			message.error("Category name is required.");
			return;
		}

		const formDataToSend = new FormData();
		formDataToSend.append("category_name", formData.categoryName);

		try {
			const response = await fetch(`${apiurl}/requestcategories/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSend,
			});

			if (!response.ok) {
				message.error("Failed to request for category.");
				return;
			}

			message.success("Category requested successfully.");
			setIsModalOpen(false);
			setFormData({ categoryName: "" });
		} catch (error) {
			message.error("Network Error! Please Try Again.");
			console.error("Error adding category:", error);
		}
	};

	const handleModalCancel = () => {
		setIsModalOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<Main>
			<div className="vendor-categories-container">
				<div className="add-btn-cont">
					<button onClick={handleAddCategory}>+ Request New Category</button>
				</div>
				{categories.length > 0 ? (
					<div className="grid-container">
						{categories.map((category, index) => (
							<>
								{category.count > 0 && (
									<div className="category-item">
										<img
											src={apiurl + category.category.thumbnail}
											alt={category.category.name}
											className="category-image"
										/>
										<div className="cat-meta">
											<h4 className="sub-category">
												{category.category.maincategory.name} -{" "}
												{category.category.name}
											</h4>

											<p className="item-count">
												{category.count}{" "}
												{category.count === 1 ? "Item" : "Items"}
											</p>
										</div>
									</div>
								)}
							</>
						))}
					</div>
				) : (
					<p className="no-categories">No categories found.</p>
				)}
			</div>
			<Modal
				title="Request New Category"
				open={isModalOpen}
				onOk={handleModalOk}
				onCancel={handleModalCancel}>
				<Input
					name="categoryName"
					placeholder="Category Name"
					value={formData.categoryName}
					onChange={handleInputChange}
					style={{ marginBottom: "1rem" }}
				/>
			</Modal>
		</Main>
	);
};

export default Categories;

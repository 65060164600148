import React, { useEffect, useState } from "react";
import { message, Spin, Modal, Form, Input, Button, Radio, Select } from "antd";
import { useAuth } from "../utils/useAuth";
import "./Profile.css";
import {useNavigate} from "react-router-dom";

const { Option } = Select;

const countryCodes = [
	{ code: "+1", name: "USA/Canada" },
	{ code: "+91", name: "India" },
	{ code: "+44", name: "UK" },
	{ code: "+61", name: "Australia" },
	{ code: "+81", name: "Japan" },
	{ code: "+971", name: "Dubai" },
];

const Profile = () => {
	const { apiurl, token, handleLogout } = useAuth();
	const [userDetails, setUserDetails] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();
	const navigate = useNavigate()

	useEffect(() => {
		fetchUserDetails();
	}, []);

	const fetchUserDetails = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/user-details/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.ok) {
				const data = await response.json();
				setUserDetails(data.data);
			} else {
				const errorData = await response.json();
				message.error(errorData.error || "Failed to fetch user details.");
			}
		} catch (error) {
			message.error("An error occurred. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const handleEdit = () => {
		setIsModalVisible(true);
		if (userDetails) {
			form.setFieldsValue({
				username: userDetails.username,
				email: userDetails.email,
				phone_number: userDetails.phone_number,
				country_code: userDetails.country_code || "+1",
				gender: userDetails.gender,
			});
		}
	};

	const handleModalCancel = () => {
		setIsModalVisible(false);
	};

	const handleFormSubmit = async (values) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/profile/edit/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				fetchUserDetails();
				message.success("User details updated successfully.");
				setIsModalVisible(false);
			} else {
				const errorData = await response.json();
				message.error(errorData.error || "Failed to update user details.");
			}
		} catch (error) {
			message.error("An error occurred. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const getInitials = (username) => {
		return username ? username.charAt(0).toUpperCase() : "?";
	};

	const handleLogoutt = () =>{
		handleLogout();
		navigate('/');
	}

	return (
		<div className="profile-container">
			{loading ? (
				<Spin size="large" />
			) : (
				userDetails && (
					<div className="profile-card">
						<div className="profile-header">
							<div className="profile-header-info">
								<h2>Personal Information</h2>
								<span className="edit-icon" onClick={handleEdit}></span>
							</div>
						</div>
						<div className="profile-details">
							<div className="profile-avatar">
								{getInitials(userDetails.username)}
							</div>
							<div className="profile-row">
								<div>
									<h4>User Name</h4>
									<p>{userDetails.username}</p>
								</div>
								<div>
									<h4>Email</h4>
									<p>{userDetails.email}</p>
								</div>
								<div>
									<h4>Contact</h4>
									<p>
										{userDetails.country_code} {userDetails.phone_number || "-"}
									</p>
								</div>
								<div>
									<h4>Gender</h4>
									<p>{userDetails.gender || "-"}</p>
								</div>
							</div>
						</div>
					</div>
				)
			)}

			<Modal
				title="Edit Personal Information"
				open={isModalVisible}
				onCancel={handleModalCancel}
				footer={null}
				className="edit-profile-modal">
				<Form form={form} layout="vertical" onFinish={handleFormSubmit}>
					<Form.Item
						name="username"
						label="Username"
						rules={[{ required: true, message: "Please enter your username" }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="email"
						label="Email"
						rules={[
							{ required: true, message: "Please enter your email" },
							{ type: "email", message: "Please enter a valid email" },
						]}>
						<Input />
					</Form.Item>
					<Form.Item label="Phone Number">
						<Input.Group compact>
							<Form.Item
								name="country_code"
								noStyle
								rules={[
									{
										required: true,
										message: "Please select your country code",
									},
								]}>
								<Select style={{ width: "20%" }} placeholder="Code">
									{countryCodes.map((code) => (
										<Option key={code.code} value={code.code}>
											{code.code}
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								name="phone_number"
								noStyle
								rules={[
									{ required: true, message: "Please enter your phone number" },
									{
										pattern: /^[0-9]{10}$/,
										message: "Please enter a valid 10-digit phone number",
									},
								]}>
								<Input style={{ width: "80%" }} placeholder="Phone Number" />
							</Form.Item>
						</Input.Group>
					</Form.Item>
					<Form.Item
						name="gender"
						label="Gender"
						initialValue="Male"
						rules={[{ required: true, message: "Please select your gender" }]}>
						<Radio.Group>
							<Radio value="Male">Male</Radio>
							<Radio value="Female">Female</Radio>
							<Radio value="Rather Not Say">Rather Not Say</Radio>
						</Radio.Group>
					</Form.Item>
					<div className="modal-footer-btns">
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Save
							</Button>
							<Button
								onClick={handleModalCancel}
								style={{ marginLeft: "10px" }}>
								Cancel
							</Button>
						</Form.Item>
					</div>
				</Form>
			</Modal>
			<button onClick={handleLogoutt} className="logout-btn">
				Logout
			</button>
		</div>
	);
};

export default Profile;

import React from "react";
import Main from "./Layout";

const Return = () => {
	return (
		<Main>
			<div>Return</div>
		</Main>
	);
};

export default Return;

import React, { useState, useEffect } from "react";
import Main from "./Layout";
import { useAuth } from "../utils/useAuth";
import "./Categories.css";
import { Card, message, Modal, Input, Select, Button } from "antd";
import Loader from "../Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";


const AdminSubCategories = () => {
	const { apiurl, token } = useAuth();
	const { id } = useParams();
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formData, setFormData] = useState({
		categoryName: "",
		thumbnail: null,
	});
	const navigate = useNavigate();

	const fetchCategories = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/admin/subcategories/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				setLoading(false);
				message.error("Failed to fetch categories");
				return;
			}

			const data = await response.json();
			setCategories(data);
		} catch (error) {
			message.error("Network Error! Please Try Again.");
			console.error("Error fetching categories:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCategories();
	}, [apiurl, token]);

	const handleAddCategory = () => {
		setIsModalOpen(true);
	};

	const handleModalOk = async () => {
		if (!formData.categoryName || !formData.thumbnail) {
			message.error("All fields are required.");
			return;
		}

		const formDataToSend = new FormData();
		formDataToSend.append("name", formData.categoryName);
		formDataToSend.append("thumbnail", formData.thumbnail);
		formDataToSend.append("category_id", id);

		try {
			const response = await fetch(`${apiurl}/subcategories/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSend,
			});

			if (!response.ok) {
				message.error("Failed to add category.");
				return;
			}

			message.success("Category added successfully.");
			setIsModalOpen(false);
			fetchCategories();
			setFormData({ categoryName: "", thumbnail: null, mainCategory: "" });

			const updatedCategories = await response.json();
			setCategories((prev) => [...prev, updatedCategories]);
		} catch (error) {
			message.error("Network Error! Please Try Again.");
			console.error("Error adding category:", error);
		}
	};

	const handleModalCancel = () => {
		setIsModalOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleFileChange = (e) => {
		setFormData((prev) => ({ ...prev, thumbnail: e.target.files[0] }));
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<Main>
			<div className="admin-subcategories-container">
				<div className="add-btn-cont">
				<button className="go-bck-btn" onClick={() => navigate(-1)}>
					Go Back
				</button>
					<button onClick={handleAddCategory}>+ Add New Category</button>
				</div>
				{categories.length > 0 ? (
					<div className="grid-container">
						{categories.map(
							(category, index) =>
								category && (
									<div key={index} className="category-item">
										<img
											src={apiurl + category.category.thumbnail}
											alt={category.category.name}
											className="category-image"
										/>
										<div className="cat-meta">
											<h4 className="sub-category">{category.category.name}</h4>

											<p className="item-count">
												{category.count}{" "}
												{category.count === 1 ? "Item" : "Items"}
											</p>
										</div>
									</div>
								)
						)}
					</div>
				) : (
					<p className="no-categories">No categories found.</p>
				)}

				<Modal
					title="Add New Category"
					open={isModalOpen}
					onOk={handleModalOk}
					onCancel={handleModalCancel}>
					<Input
						name="categoryName"
						placeholder="Sub Category Name"
						value={formData.categoryName}
						onChange={handleInputChange}
						style={{ marginBottom: "1rem" }}
					/>
					<input
						type="file"
						accept="image/*"
						onChange={handleFileChange}
						style={{ marginBottom: "1rem" }}
					/>
				</Modal>
			</div>
		</Main>
	);
};

export default AdminSubCategories;

import React, { useEffect, useState } from "react";
import BlogPost from "./BlogPost";
import Newsletter from "./Newsletter";
import "./Blog.css";
import { useAuth } from "../utils/useAuth";

import Main from "../user/Layout";

import { message } from "antd";
import Loader from "../Loader/Loader";
import { Link, useNavigate } from "react-router-dom";

function Blog() {
	const { apiurl } = useAuth();
	const [blogPosts, setBlogPosts] = useState([]);
	const [loading, setLoading] = useState();
	const navigate = useNavigate();

	useEffect(() => {
		fetchBlogPosts();
	}, []);

	const fetchBlogPosts = async () => {
		setLoading(true);

		try {
			const response = await fetch(`${apiurl}/blogposts/`, {
				method: "GET",
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.status} ${response.statusText}`);
			}
			setLoading(false);
			const data = await response.json();
			setBlogPosts(data);
		} catch (err) {
			message.error("Error fetching blog posts: ");
		} finally {
			setLoading(false);
		}
	};

	console.log(loading);
	if (loading) {
		return <Loader />;
	}

	return (
		<Main>
			<div className="blog-container">
				<div className="blog-container">
					<header className="blog-header">
						<h1>Blog</h1>
						<p>
							Make your next movement more stylish with our latest designs of
							clothing
						</p>
					</header>

					<div className="blog-grid">
						{blogPosts.map((post, index) => (
							<Link to={`/blogs/${post.id}`}>
								<BlogPost key={index} {...post} />
							</Link>
						))}
					</div>

					<Newsletter />
				</div>
			</div>
		</Main>
	);
}

export default Blog;

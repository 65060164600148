import { Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthRoute } from "./components/utils/AuthRoute";
import Login from "./components/userauth/login";
import MainHome from "./components/Home/Home";
import VendorSignup from "./components/vendor/Signup";
import Signup from "./components/userauth/signup";
import AddProduct from "./components/vendor/AddProduct";
import ProductList from "./components/user/Products";
import Commingsoon from "./components/commingsoon/Commingsoon";
import Homepage from "./components/Home/Homepage";
import Home from "./components/user/Home";
import SpecificProduct from "./components/user/SpecificProduct";
import Cart from "./components/user/Cart";
import OrderPlaced from "./components/user/OrderPlaced";
import Men from "./components/user/Men";
import Inventory from "./components/vendor/Inventory";
import VendorOrders from "./components/vendor/Orders";
import Women from "./components/user/Women";
import VendorHome from "./components/vendor/Home";
import Kids from "./components/user/Kids";
import Beauty from "./components/user/Beauty";
import Return from "./components/vendor/Return";
import Offers from "./components/vendor/Offers";
import UsersInfo from "./components/vendor/UsersInfo";
import Categories from "./components/vendor/Categories";
import TermsAndConditions from "./components/policies/TermsAndConditions";
import PrivacyPolicy from "./components/policies/PrivacyPolicy";
import ReturnPolicy from "./components/policies/ReturnPolicy";
import CancellationPolicy from "./components/policies/CancellationPolicy";
import RefundPolicy from "./components/policies/RefundPolicy";
import Coupons from "./components/vendor/Coupon";
import AdminHome from "./components/admin/Home";
import AdminCategories from "./components/admin/Categories";
import AdminInventory from "./components/admin/Inventory";
import AdminAddProductForm from "./components/admin/AddProduct";
import AdminOrders from "./components/admin/Orders";
import AdminReturns from "./components/admin/Return";
import AdminCoupons from "./components/admin/Coupon";
import AdminAccount from "./components/admin/UsersInfo";
import AdminSubCategories from "./components/admin/SubCategory";
import Users from "./components/admin/Users";
import AdminVendors from "./components/admin/Vendors";
import SpecificUser from "./components/admin/SpecificUser";
import SpecificVendor from "./components/admin/SpecificVendor";

import Contact from "./components/contact/contact";
import Home2 from "./components/vendorHome/home";
import Blog from "./components/Blogs/Blog";
import BlogPostForm from "./components/Blogs/BlogPostForm";
import SpecificBlog from "./components/BlogSpecific/SpecificBlog";
import Notifications from "./components/Home/Notifications";
import FAQ from "./components/user/FAQ";
import About from "./components/About/About";

function App() {
	return (
		<>
			<Routes>
				{/* <Route path="/" element={<Commingsoon />}></Route> */}
				<Route path="/login" element={<Login />} />
				<Route path="/signup" element={<Signup />} />
				<Route path="/products" element={<ProductList />} />
				<Route path="/men" element={<Men />} />
				<Route path="/kids" element={<Kids />} />

				<Route path="/women" element={<Women />} />
				<Route path="/beauty" element={<Beauty />} />
				<Route path="/about" element={<About />} />

				<Route path="/faq" element={<FAQ />} />
				<Route path="/terms-conditions" element={<TermsAndConditions />} />
				<Route path="/policies/privacy" element={<PrivacyPolicy />} />
				<Route path="/policies/return" element={<ReturnPolicy />} />
				<Route path="/policies/cancellation" element={<CancellationPolicy />} />
				<Route path="/policies/refund" element={<RefundPolicy />} />
				<Route path="/products/:id" element={<SpecificProduct />} />
				<Route path="/vendor/signup" element={<VendorSignup />} />
				<Route path="/blogs" element={<Blog />} />
				<Route path="/blogform" element={<BlogPostForm />} />
				<Route path="/blogs/:id" element={<SpecificBlog />} />
				<Route path="/sheagleuniform" element={<Commingsoon />} />
				<Route path="/sheaglebtb" element={<Commingsoon />} />

				<Route path="/contact" element={<Contact />} />
				<Route path="/vendor" element={<Home2 />} />

				<Route path="/" element={<Home />} />
				<Route path="/" element={<AuthRoute />}>
					<Route path="/profile" element={<MainHome />} />
					<Route path="/vendor/orders" element={<VendorOrders />} />
					<Route path="/vendor/addproduct" element={<AddProduct />} />
					<Route path="/vendor/returns" element={<Return />} />
					<Route path="/vendor/coupons" element={<Coupons />} />
					<Route path="/vendor/account" element={<UsersInfo />} />
					<Route path="/vendor/categories" element={<Categories />} />

					<Route path="/vendor/home" element={<VendorHome />} />
					<Route path="/admin/categories" element={<AdminCategories />} />
					<Route path="/admin/users" element={<Users />} />
					<Route path="/admin/users/:id" element={<SpecificUser />} />
					<Route
						path="/admin/categories/:id"
						element={<AdminSubCategories />}
					/>
					<Route path="/vendor/products" element={<Inventory />} />

					<Route path="/admin/home" element={<AdminHome />} />
					<Route path="/admin/addproduct" element={<AdminAddProductForm />} />
					<Route path="/admin/products" element={<AdminInventory />} />
					<Route path="/admin/orders" element={<AdminOrders />} />
					<Route path="/admin/vendors" element={<AdminVendors />} />
					<Route path="/admin/vendors/:id" element={<SpecificVendor />} />
					<Route path="/admin/returns" element={<AdminReturns />} />
					<Route path="/admin/coupons" element={<AdminCoupons />} />
					<Route path="/admin/account" element={<AdminAccount />} />
					<Route path="/cart" element={<Cart />} />
					<Route path="/ordersuccess" element={<OrderPlaced />} />

					<Route path="/notifications" element={<Notifications />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;

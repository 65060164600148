import React from "react";
import "./BlogPost.css";
import { useAuth } from "../utils/useAuth";

function BlogPost({ thumbnail, category, created_at, title, content, id }) {
	const { apiurl } = useAuth();

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toISOString().split("T")[0];
	};

	return (
		<div className="blog-post" style={{ cursor: "pointer" }}>
			<img
				src={`${apiurl}${thumbnail}`}
				alt={title}
				className="blog-post-image"
			/>
			<div className="blog-post-content">
				<div className="blog-post-meta">
					<h2 className="blog-post-title">{title}</h2>
				</div>
					<div
						className="date"
						style={{ display: "flex", alignItems: "center" }}>
						{formatDate(created_at)}
					</div>
				<div
					className="blog-post-description"
					dangerouslySetInnerHTML={{ __html: content }}></div>
			</div>
		</div>
	);
}

export default BlogPost;

const countries = [
	{
		code: "AE",
		name: "Dubai (UAE)",
		currency: "AED",
		symbol: "د.إ",
		flag: "🇦🇪",
		mobileCode: "+971",
	},
	{
		code: "IN",
		name: "India",
		currency: "INR",
		symbol: "₹",
		flag: "🇮🇳",
		mobileCode: "+91",
	},
	{
		code: "US",
		name: "United States",
		currency: "USD",
		symbol: "$",
		flag: "🇺🇸",
		mobileCode: "+1",
	},
	{
		code: "GB",
		name: "United Kingdom",
		currency: "GBP",
		symbol: "£",
		flag: "🇬🇧",
		mobileCode: "+44",
	},
	{
		code: "AU",
		name: "Australia",
		currency: "AUD",
		symbol: "A$",
		flag: "🇦🇺",
		mobileCode: "+61",
	},
];


export default countries;
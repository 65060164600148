import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import "./VendorSpecificProduct.css";
import {
	Breadcrumb,
	Form,
	Image,
	Input,
	InputNumber,
	message,
	Modal,
	Popconfirm,
	Progress,
	Rate,
	Select,
	Space,
	Tabs,
	Upload,
} from "antd";
import {
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	StarFilled,
} from "@ant-design/icons";

import TabPane from "antd/es/tabs/TabPane";

const VendorSpecificProduct = ({ id, handleBack }) => {
	const { apiurl, token, currentCountry } = useAuth();
	const [productdetails, setProductDetails] = useState(null);
	const [selectedColor, setSelectedColor] = useState(null);
	const [selectedSize, setSelectedSize] = useState(null);
	const [filteredVariants, setFilteredVariants] = useState([]);
	const [availableColors, setAvailableColors] = useState([]);
	const [colors, setColors] = useState([]);
	const [countries, setCountries] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [availableSizes, setAvailableSizes] = useState([]);
	const [reviews, setReviews] = useState([]);
	const [editingVariant, setEditingVariant] = useState(null);
	const [newVariant, setNewVariant] = useState({
		size: "",
		color: "",
		stock_quantity: 0,
		weight: 0,
		length: 0,
		width: 0,
		height: 0,
		images: [],
		prices: [],
	});
	const [filteredReviews, setFilteredReviews] = useState(reviews);
	const [filter, setFilter] = useState("all");
	const [sortOrder, setSortOrder] = useState("recent");

	const navigate = useNavigate();

	const fetchProductDetails = async () => {
		const response = await fetch(`${apiurl}/products/${id}`);
		const data = await response.json();
		setProductDetails(data);
		setSelectedColor(data.product_varients[0].color?.name);
		setSelectedSize(data.product_varients[0].size);
		setFilteredVariants(data.product_varients);
		setAvailableColors([
			...new Set(data.product_varients.map((variant) => variant.color?.name)),
		]);
	};

	useEffect(() => {
		if (productdetails && selectedColor) {
			const filteredByColor = productdetails.product_varients.filter(
				(variant) => variant.color.name === selectedColor
			);
			setFilteredVariants(filteredByColor);

			const uniqueSizes = [
				...new Set(filteredByColor.map((variant) => variant.size)),
			];
			setAvailableSizes(uniqueSizes);

			setSelectedSize(filteredByColor[0]?.size);
		}
	}, [selectedColor, productdetails]);

	const fetchCountries = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/countries`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				setCountries(data);
			}
		} catch (error) {
			console.error("Error fetching countries:", error);
		} finally {
			setLoading(false);
		}
	};
	const fetchColours = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/colors/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				setColors(data);
			}
		} catch (error) {
			console.error("Error fetching countries:", error);
		} finally {
			setLoading(false);
		}
	};

	const fetchReviews = async () => {
		const response = await fetch(`${apiurl}/reviews/product/${id}`);
		const data = await response.json();
		setReviews(data);
	};

	const filterByRating = (rating) => {
		return reviews.filter((review) => review.rating === rating);
	};

	const filterWithImages = () => {
		return reviews.filter((review) => review.images.length > 0);
	};

	const sortReviews = (reviews, order) => {
		switch (order) {
			case "recent":
				return reviews.sort(
					(a, b) => new Date(b.created_at) - new Date(a.created_at)
				);
			case "rating_desc":
				return reviews.sort((a, b) => b.rating - a.rating);
			case "rating_asc":
				return reviews.sort((a, b) => a.rating - b.rating);
			default:
				return reviews;
		}
	};

	useEffect(() => {
		fetchProductDetails();
		fetchCountries();
		fetchColours();
		fetchReviews();
	}, []);

	useEffect(() => {
		let filtered = [...reviews];

		if (filter !== "all") {
			filtered = filterByRating(parseInt(filter));
		}

		if (filter === "with_images") {
			filtered = filterWithImages();
		}

		filtered = sortReviews(filtered, sortOrder);

		setFilteredReviews(filtered);
	}, [filter, sortOrder, reviews]);

	if (!productdetails) {
		return <div>Loading...</div>;
	}

	const { name, product_varients } = productdetails;
	const selectedVariant = filteredVariants.find(
		(variant) =>
			variant.color?.name === selectedColor && variant?.size === selectedSize
	);

	const handleSizeClick = (size) => {
		if (availableSizes.includes(size)) {
			setSelectedSize(size);
		}
	};

	const handleDelete = async () => {
		const response = await fetch(`${apiurl}/variants/${selectedVariant.id}/`, {
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.ok) {
			await fetchProductDetails();
			message.success("Variant deleted successfully");
		} else {
			console.error("Failed to delete the variant");
		}
	};

	const handleEdit = async () => {
		if (selectedVariant) {
			const processedImages = await Promise.all(
				selectedVariant.images.map(async (img) => {
					const fileUrl = apiurl + img.image;
					const response = await fetch(fileUrl);
					const blob = await response.blob();
					return {
						uid: img.id,
						url: fileUrl,
						originFileObj: new File([blob], img.image, { type: blob.type }),
					};
				})
			);

			setNewVariant({
				size: selectedVariant.size,
				color: selectedVariant.color?.id,
				stock_quantity: selectedVariant.stock_quantity,
				weight: selectedVariant.weight,
				length: selectedVariant.length,
				width: selectedVariant.width,
				height: selectedVariant.height,
				images: processedImages,
				id: selectedVariant.id,
				prices: selectedVariant.country_specific_prices.map((price) => ({
					country_name: price.country_name,
					price: price.price,
				})),
			});
			setEditingVariant(selectedVariant);
			setIsModalVisible(true);
		}
	};

	const handleAddVariant = async () => {
		try {
			setLoading(true);

			if (editingVariant) {
				const formData = new FormData();

				formData.append("size", newVariant.size);
				formData.append("color", newVariant.color);
				formData.append("stock_quantity", newVariant.stock_quantity);
				formData.append("weight", newVariant.weight);
				formData.append("length", newVariant.length);
				formData.append("width", newVariant.width);
				formData.append("height", newVariant.height);

				newVariant.prices.forEach((price, index) => {
					formData.append(`prices[${index}][country_name]`, price.country_name);
					formData.append(`prices[${index}][price]`, price.price);
					formData.append(`prices[${index}][discount]`, price.discount || 0);
					formData.append(
						`prices[${index}][discount_type]`,
						price.discount_type || "Amount"
					);
				});

				newVariant.images.forEach((img) => {
					if (img.originFileObj) {
						formData.append("images", img.originFileObj);
					}
				});

				const response = await fetch(
					`${apiurl}/variants/${editingVariant.id}/`,
					{
						method: "PUT",
						headers: {
							Authorization: `Bearer ${token}`,
						},
						body: formData,
					}
				);

				if (response.ok) {
					await fetchProductDetails();
					setIsModalVisible(false);
					setEditingVariant(null);
					message.success("Variant updated successfully");
				} else {
					console.error("Failed to update the variant");
				}
			} else {
			}
		} catch (error) {
			console.error("Error updating variant:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleVariantImageUpload = ({ fileList }) => {
		setNewVariant((prev) => ({
			...prev,
			images: fileList.map((file) => ({
				uid: file.uid,
				name: file.name,
				url: file.url || URL.createObjectURL(file.originFileObj),
				originFileObj: file.originFileObj,
			})),
		}));
	};

	const formatDate = (date) => {
		return new Date(date).toLocaleDateString();
	};

	const getRatingCount = (rating) => {
		return reviews.filter(
			(review) => parseInt(review.rating) === parseInt(rating)
		).length;
	};

	const renderRatingRow = (rating, count, totalReviews) => {
		const percentage = totalReviews ? (count / totalReviews) * 100 : 0;

		return (
			<div
				style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
				key={rating}>
				{rating}
				<Rate
					disabled
					defaultValue={rating}
					count={1}
					style={{ fontSize: 16, color: "#34cf0b", marginLeft: 8 }}
				/>

				<div style={{ width: 160, marginLeft: 16 }}>
					<Progress
						percent={percentage.toFixed(1)}
						showInfo={false}
						strokeColor={"#34cf0b"}
					/>
				</div>

				<span style={{ marginLeft: 8 }}>{count}</span>
			</div>
		);
	};

	return (
		<>
			<div className="vendor-specific-product-main">
				<div className="breadcrumb-container">
					<Breadcrumb>
						<Breadcrumb.Item>
							<Link to="/">Home</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<div style={{ cursor: "pointer" }} onClick={handleBack}>
								Products
							</div>
						</Breadcrumb.Item>
						<Breadcrumb.Item>{name}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div className="product-cont-main">
					<Tabs>
						<TabPane tab="Details" key="1">
							<div className="specific-product">
								<div className="specific-product-left">
									<div className="specific-product-image">
										{filteredVariants.length > 0 &&
										filteredVariants[0].images.length > 0 ? (
											<img
												src={apiurl + filteredVariants[0].images[0].image}
												alt="Product"
											/>
										) : (
											<img
												src={apiurl + product_varients[0]?.images[0]?.image}
												alt="Product"
											/>
										)}

										{}
									</div>

									<div className="specific-product-thumbnails">
										{filteredVariants.length > 0 &&
											filteredVariants[0].images.map((image, index) => (
												<img
													key={index}
													src={apiurl + image.image}
													alt={`Thumbnail ${index}`}
													className="thumbnail-image"
													onClick={() => {
														const mainImage = document.querySelector(
															".specific-product-image img"
														);
														mainImage.src = apiurl + image.image;
													}}
												/>
											))}
									</div>
								</div>

								<div className="specific-product-right">
									<h1 className="specific-product-title">{name}</h1>
									<div className="specific-product-meta">
										<p>Sold : {selectedVariant.num_buyed}</p>
										<p>
											<strong>|</strong>
										</p>
										<p>
											Rating : <StarFilled style={{ color: "gold" }} />{" "}
											{selectedVariant.avg_rating}/5
										</p>
										<p>
											<strong>|</strong>
										</p>
										<p>Stock : {selectedVariant.stock_quantity}</p>
									</div>

									{selectedColor && availableColors.length > 0 && (
										<div className="specific-product-color">
											<label>
												Color: <span>{selectedColor}</span>
											</label>
											<div className="color-selection">
												{availableColors.map((color) => (
													<button
														key={color}
														className={`color-button ${
															color === selectedColor ? "selected" : ""
														}`}
														onClick={() => setSelectedColor(color)}>
														{color}
													</button>
												))}
											</div>
										</div>
									)}
									{availableSizes.length > 0 && (
										<div className="specific-product-size">
											<label>
												Size: <span>{selectedSize}</span>
											</label>
											<div className="size-selection">
												{availableSizes.map((size) => (
													<button
														key={size}
														className={`size-button ${
															size === selectedSize ? "selected" : ""
														}`}
														onClick={() => handleSizeClick(size)}>
														{size}
													</button>
												))}
											</div>
										</div>
									)}

									<div className="product-description">
										<div>
											<strong>Description:</strong>
										</div>
										<p>{productdetails.description}</p>
									</div>
								</div>
							</div>
						</TabPane>
						<TabPane tab="Size Guide" key="2">
							<div className="product-description">Size Guide</div>
						</TabPane>
						<TabPane tab="Pricing" key="3">
							<div className="specific-product-prices">
								<table border="1">
									<thead>
										<tr>
											<th>Country</th>
											<th>Price</th>
											<th>Discount Type</th>
											<th>Discount</th>
										</tr>
									</thead>
									<tbody>
										{selectedVariant.country_specific_prices?.length > 0 ? (
											selectedVariant.country_specific_prices.map((price) => (
												<tr key={price.id}>
													<td>{price.country_name}</td>
													<td>{price.price}</td>
													<td>{price.discount_type}</td>
													<td>{price.discount}</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="4">No prices available</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</TabPane>
						<TabPane tab="Reviews" key="4">
							<div className="specific-product-reviews">
								<div className="spproduct-reviews-main">
									<div className="left-sec">
										<h2>Customer Reviews</h2>
										<div className="avg-reviews">
											<h1>{productdetails.avg_rating}</h1>
											<div>
												<Rate
													disabled
													defaultValue={productdetails.avg_rating}
													count={5}
													style={{ fontSize: 16, color: "#34cf0b" }}
												/>
												<p>
													Based on {productdetails.total_reviews}{" "}
													{productdetails.total_reviews === 1
														? "review"
														: "reviews"}
												</p>
											</div>
										</div>
										<div className="reviews-stats">
											{Array.from({ length: 5 }, (_, i) => {
												const rating = 5 - i;
												return renderRatingRow(
													rating,
													getRatingCount(rating),
													reviews.length
												);
											})}
										</div>
									</div>

									<div className="filter-grid">
										<div className="filters">
											<div>
												<select
													onChange={(e) => setFilter(e.target.value)}
													value={filter}>
													<option value="all">All reviews</option>
													<option value="5">5 Stars</option>
													<option value="4">4 Stars</option>
													<option value="3">3 Stars</option>
													<option value="2">2 Stars</option>
													<option value="1">1 Star</option>
													<option value="with_images">With Images</option>
												</select>
											</div>

											<div>
												<select
													onChange={(e) => setSortOrder(e.target.value)}
													value={sortOrder}>
													<option value="recent">Most recent</option>
													<option value="rating_desc">
														Rating: High to Low
													</option>
													<option value="rating_asc">
														Rating: Low to High
													</option>
												</select>
											</div>
										</div>
										{filteredReviews.length > 0 ? (
											<div className="product-reviews">
												{filteredReviews.map((review) => (
													<div key={review.id} className="review">
														<div className="review-header">
															<div>
																<h4>{review.user}</h4>
																<Rate
																	disabled
																	value={review.rating}
																	style={{ fontSize: 16, color: "#bbb" }}
																/>
																<span style={{ fontSize: 11 }}>
																	{" "}
																	{formatDate(review.created_at)}
																</span>
															</div>
														</div>
														<p>{review.review}</p>
														<div className="review-images">
															{review.images.map((image, index) => (
																<Image
																	key={index}
																	src={apiurl + image.image}
																	alt="review"
																/>
															))}
														</div>
													</div>
												))}
											</div>
										) : (
											<p>No reviews yet</p>
										)}
									</div>
								</div>
							</div>
						</TabPane>
					</Tabs>
				</div>
				<div className="sp-prod-actions">
					<button onClick={() => handleEdit()}>
						<EditOutlined /> Edit Varient
					</button>
					<button>
						<Popconfirm
							title="Are you sure to delete this variant?"
							onConfirm={() => handleDelete()}
							okText="Yes"
							cancelText="No">
							<DeleteOutlined /> Delete Variant
						</Popconfirm>
					</button>
				</div>
			</div>

			<Modal
				title={editingVariant !== null ? "Edit Variant" : "Add Variant"}
				open={isModalVisible}
				onOk={handleAddVariant}
				className="variant-modal"
				onCancel={() => {
					setIsModalVisible(false);
					setEditingVariant(null);
				}}>
				<Form layout="vertical" className="variant-form">
					<div className="form-item-group">
						<Form.Item label="Size" className="form-item">
							<Input
								value={newVariant.size}
								onChange={(e) =>
									setNewVariant((prev) => ({ ...prev, size: e.target.value }))
								}
								className="form-input"
							/>
						</Form.Item>
						<Form.Item label="Color" className="form-item">
							<Select
								value={newVariant.color}
								onChange={(value) =>
									setNewVariant((prev) => ({ ...prev, color: value }))
								}
								className="form-select">
								{colors.map((color) => (
									<Select.Option
										key={color.id}
										value={color.id}
										className="form-option">
										{color.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Stock Quantity" required className="form-item">
							<InputNumber
								name="stock_quantity"
								value={newVariant.stock_quantity}
								onChange={(value) =>
									setNewVariant((prev) => ({
										...prev,
										stock_quantity: value,
									}))
								}
								className="form-input-number"
							/>
						</Form.Item>
					</div>
					<div className="form-item-group">
						<Form.Item label="Weight" required className="form-item">
							<InputNumber
								name="weight"
								value={newVariant.weight}
								onChange={(value) =>
									setNewVariant((prev) => ({
										...prev,
										weight: value,
									}))
								}
								className="form-input-number"
							/>
						</Form.Item>
						<Form.Item label="Length" required className="form-item">
							<InputNumber
								name="length"
								value={newVariant.length}
								onChange={(value) =>
									setNewVariant((prev) => ({
										...prev,
										length: value,
									}))
								}
								className="form-input-number"
							/>
						</Form.Item>
						<Form.Item label="Width" required className="form-item">
							<InputNumber
								name="width"
								value={newVariant.width}
								onChange={(value) =>
									setNewVariant((prev) => ({
										...prev,
										width: value,
									}))
								}
								className="form-input-number"
							/>
						</Form.Item>
						<Form.Item label="Height" required className="form-item">
							<InputNumber
								name="height"
								value={newVariant.height}
								onChange={(value) =>
									setNewVariant((prev) => ({
										...prev,
										height: value,
									}))
								}
								className="form-input-number"
							/>
						</Form.Item>
					</div>
					<Form.Item label="Variant Images" required className="form-item">
						<Upload
							listType="picture-card"
							fileList={newVariant.images}
							onChange={handleVariantImageUpload}
							beforeUpload={() => false}>
							<div>
								<PlusOutlined />
								<div style={{ marginTop: 8 }}>Upload</div>
							</div>
						</Upload>
					</Form.Item>

					<Form.Item label="Prices" required className="form-item">
						<Space direction="vertical" style={{ width: "100%" }}>
							{countries.map((country) => (
								<div key={country.name} className="country-prices">
									<label>{country.name}</label>
									<span>Amount</span>
									<InputNumber
										value={
											newVariant.prices.find(
												(p) => p.country_name === country.name
											)?.price || 0
										}
										className="form-input-number"
										onChange={(value) =>
											setNewVariant((prev) => {
												const existingPrices = prev.prices.filter(
													(p) => p.country_name !== country.name
												);
												const updatedPrice = {
													country_name: country.name,
													price: value,
													discount:
														prev.prices.find(
															(p) => p.country_name === country.name
														)?.discount || 0,
													discount_type:
														prev.prices.find(
															(p) => p.country_name === country.name
														)?.discount_type || "Amount",
												};
												return {
													...prev,
													prices: [...existingPrices, updatedPrice],
												};
											})
										}
									/>

									<span>Discount Type</span>
									<Select
										value={
											newVariant.prices.find(
												(p) => p.country_name === country.name
											)?.discount_type || "Amount"
										}
										onChange={(value) =>
											setNewVariant((prev) => {
												const existingPrices = prev.prices.filter(
													(p) => p.country_name !== country.name
												);
												const updatedPrice = {
													country_name: country.name,
													price:
														prev.prices.find(
															(p) => p.country_name === country.name
														)?.price || 0,
													discount:
														prev.prices.find(
															(p) => p.country_name === country.name
														)?.discount || 0,
													discount_type: value,
												};
												return {
													...prev,
													prices: [...existingPrices, updatedPrice],
												};
											})
										}>
										<option value="Amount">Amount</option>
										<option value="Percentage">Percentage</option>
									</Select>

									<span>Discount</span>
									<InputNumber
										value={
											newVariant.prices.find(
												(p) => p.country_name === country.name
											)?.discount || 0
										}
										className="form-input-number"
										onChange={(value) =>
											setNewVariant((prev) => {
												const existingPrices = prev.prices.filter(
													(p) => p.country_name !== country.name
												);
												const updatedPrice = {
													country_name: country.name,
													price:
														prev.prices.find(
															(p) => p.country_name === country.name
														)?.price || 0,
													discount: value,
													discount_type:
														prev.prices.find(
															(p) => p.country_name === country.name
														)?.discount_type || "Amount",
												};
												return {
													...prev,
													prices: [...existingPrices, updatedPrice],
												};
											})
										}
									/>
								</div>
							))}
						</Space>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default VendorSpecificProduct;

import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import "./AdminMetrics.css";
import Main from "./Layout";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
);

const AdminHome = () => {
	const { apiurl, token, currentCountry } = useAuth();
	const [metrics, setMetrics] = useState(null);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const fetchMetrics = async () => {
		try {
			const response = await fetch(`${apiurl}/admin/dashboard`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				setMetrics(data);
			}
		} catch (error) {
			console.error("Error fetching metrics:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchMetrics();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	if (!metrics) {
		return <div>Error loading metrics</div>;
	}

	const {
		average_revenue,
		monthly_data,
		sales_by_category,
		recent_orders,
		top_products,
	} = metrics;

	const reversedMonthlyData = [...monthly_data].reverse();

	const revenueData = {
		labels: reversedMonthlyData.map((item) => item.month),
		datasets: [
			{
				label: "Revenue",
				data: reversedMonthlyData.map((item) => item.revenue),
				borderColor: "#6366f1",
				backgroundColor: "#6366f1",
				tension: 0.5,
				pointBackgroundColor: "#6366f1",
			},
			{
				label: "Orders",
				data: reversedMonthlyData.map((item) => item.orders),
				borderColor: "#f43f5e",
				backgroundColor: "#f43f5e",
				tension: 0.5,
				pointBackgroundColor: "#f43f5e",
			},
		],
	};

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				position: "top",
				align: "end",
				boxWidth: "1px",
				boxHeight: "1px",
				usePointStyle: true,
			},
			tooltip: {
				enabled: true,
			},
		},
		scales: {
			x: {
				type: "category",
				title: {
					display: true,
					text: "Months",
				},
			},
			y: {
				type: "linear",
				title: {
					display: true,
					text: "Value",
				},
				ticks: {
					beginAtZero: true,
				},
			},
		},
	};

	const salesCategoryData = {
		labels: sales_by_category.map((item) => item.category),
		datasets: [
			{
				data: sales_by_category.map((item) => item.total_sales),
				backgroundColor: [
					"#4caf50",
					"#f44336",
					"#ff9800",
					"#2196f3",
					"#9c27b0",
				],
				hoverOffset: 4,
			},
		],
	};

	const doughnutChartOptions = {
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				position: "bottom",

				labels: {
					font: {
						size: 14,
					},
					padding: 15,
				},
			},
			tooltip: {
				enabled: true,
				callbacks: {
					label: function (tooltipItem) {
						return `${tooltipItem.label}: ${currentCountry.symbol}${tooltipItem.raw}`;
					},
				},
			},
		},
	};

	return (
		<Main>
			<div className="metrics-container">
				<div className="left-cont">
					<div className="metrics-header">
						<div className="metric">
							<h3>Average Revenue</h3>
							<p>${average_revenue.current_month}</p>
							<span
								className={
									average_revenue.revenue_change_percentage > 0
										? "positive"
										: "negative"
								}>
								{average_revenue.revenue_change_percentage > 0 ? "▲" : "▼"}
								{average_revenue.revenue_change_percentage}%
							</span>
						</div>
						<div className="metric">
							<h3>Customer Returns</h3>
							<p>{metrics.customer_returns}</p>
						</div>
					</div>
					<div className="charts-container">
						<div className="chart">
							<h3>Revenue vs Orders</h3>
							<Line data={revenueData} options={chartOptions} />
						</div>
					</div>

					<div className="recent-orders">
						<h3>Recent Orders</h3>
						<table>
							<thead>
								<tr>
									<th>No</th>
									<th>Customer Name</th>
									<th>City</th>
									<th>Order Date</th>
									<th>Status</th>
									<th>Amount</th>
								</tr>
							</thead>
							<tbody>
								{recent_orders.map((order, index) => (
									<tr key={order.id}>
										<td>#{order.id}</td>
										<td>{order.customer_name}</td>
										<td>{order.address.city}</td>
										<td>{new Date(order.created_at).toLocaleDateString()}</td>
										<td className={order.status.toLowerCase()}>
											{order.status}
										</td>
										<td>
											{currentCountry.symbol}
											{order.price}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className="right-cont">
					<div className="chart">
						<h3>Sales by Category</h3>
						<div className="doughnut-chart">
							<Doughnut
								data={salesCategoryData}
								options={doughnutChartOptions}
							/>
						</div>
					</div>
					<div className="top-products">
						<h3>Top Products</h3>

						<div className="top-product-list">
							{top_products.map((item, index) => {
								const { product, total_quantity } = item;
								const productImage =
									product.images?.[0]?.image || "/placeholder.png";

								return (
									<div
										className="top-product-card"
										key={index}
										onClick={() => navigate("/vendor/inventory")}>
										<div className="top-product-image">
											<img
												src={apiurl + productImage}
												alt={product.product_name}
											/>
										</div>
										<div className="product-details">
											<h4>{product.product_name}</h4>
											<p>
												<strong>Items Sold:</strong> {total_quantity}
											</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</Main>
	);
};

export default AdminHome;

import React from "react";
import Main from "../user/Layout";
import { Collapse } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import "./home.css";
import Banner1 from "./../../images/VendorHome/Banner1.png";
import Banner2 from "./../../images/VendorHome/Banner2.png";
import Star from "./../../images/VendorHome/star.svg";
import Faq1 from "./../../images/VendorHome/Faq1.png";
import Faq2 from "./../../images/VendorHome/Faq2.png";
import Faq3 from "./../../images/VendorHome/Faq3.png";
import Faq4 from "./../../images/VendorHome/Faq4.png";

const Home2 = () => {
	const { Panel } = Collapse;
	const data = [
		{
			question: "What benefits do vendors get by partnering with SHEagle?",
			answer:
				"We accept returns within 30 days of purchase with the original receipt.",
		},
		{
			question: "What is the commission fee for selling on SHEagle?",
			answer:
				"Yes, we ship to most countries worldwide. Shipping charges apply.",
		},
		{
			question: "Are there any other charges like listing or shipping fees?",
			answer:
				"No listing fees if they do themselves! Vendors pay only the commission fee. Shipping fees are charged to customers or included in product pricing, as agreed.",
		},
		{
			question: "What is your return policy?",
			answer:
				"We accept returns within 30 days of purchase with the original receipt.",
		},
		{
			question: "Do you ship internationally?",
			answer:
				"No listing fees if they do themselves! Vendors pay only the commission fee. Shipping fees are charged to customers or included in product pricing, as agreed.",
		},
	];

	const customExpandIcon = (panelProps) => {
		const iconStyle = {
			// border: '2px solid',
			borderRadius: "50%",
			padding: "5px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "30px",
			height: "30px",
		};

		return panelProps.isActive ? (
			<UpOutlined
				style={{ ...iconStyle, backgroundColor: "#ff0077", color: "white" }}
			/>
		) : (
			<DownOutlined
				style={{ ...iconStyle, backgroundColor: "#F7F8FA", color: "#000" }}
			/>
		);
	};

	return (
		<Main>
			<div className="Vendor-HomeSection">
				<div className="banner1">
					<img src={Banner1} alt="Banner1" />
					<div className="matter">
						<div className="line1">YOUR CRAFT,</div>
						<div className="line2">OUR COMMUNITY</div>
						<div className="line3">
							Partner for <span className="highlight">Success</span>
						</div>
					</div>
				</div>
				<div className="banner2">
					<img src={Banner2} alt="Banner2" />
					<div className="logo">
						<img src={Star} alt="Star logo" />
					</div>
					<div className="matter">
						<div className="question">
							<div className="line1">Why should vendors</div>
							<div className="line2">
								Join <span className="highlight" />
								SHEagle?
							</div>
						</div>
						<div className="answer">
							Sheagle is a premier global eCommerce marketplace connecting
							vendors with a diverse audience worldwide. By joining us, you can
							expand your brand reach, boost sales, and grow your business
							internationally.
						</div>
					</div>
				</div>
				<div className="faqs">
					<div className="section">
						<img src={Faq1} alt="Faq1" />
						<div className="faq">
							<Collapse
								expandIcon={customExpandIcon}
								expandIconPosition="end"
								bordered={false}>
								{data.map((item, index) => (
									<Panel header={item.question} key={index}>
										<p>{item.answer}</p>
									</Panel>
								))}
							</Collapse>
						</div>
					</div>
					<div className="section reverse">
						<div className="faq">
							<Collapse
								expandIcon={customExpandIcon}
								expandIconPosition="end"
								bordered={false}>
								{data.map((item, index) => (
									<Panel header={item.question} key={index}>
										<p>{item.answer}</p>
									</Panel>
								))}
							</Collapse>
						</div>
						<img src={Faq2} alt="Faq2" />
					</div>
					<div className="section">
						<img src={Faq3} alt="Faq3" />
						<div className="faq">
							<Collapse
								expandIcon={customExpandIcon}
								expandIconPosition="end"
								bordered={false}>
								{data.map((item, index) => (
									<Panel header={item.question} key={index}>
										<p>{item.answer}</p>
									</Panel>
								))}
							</Collapse>
						</div>
					</div>
					<div className="section reverse">
						<div className="faq">
							<Collapse
								expandIcon={customExpandIcon}
								expandIconPosition="end"
								bordered={false}>
								{data.map((item, index) => (
									<Panel header={item.question} key={index}>
										<p>{item.answer}</p>
									</Panel>
								))}
							</Collapse>
						</div>
						<img src={Faq4} alt="Faq4" />
					</div>
				</div>
			</div>
		</Main>
	);
};

export default Home2;

import React from 'react'
import './Newsletter.css'
import { useNavigate } from 'react-router-dom'
import blogicon from '../../images/Blogs/blogicon.png'

function Newsletter() {
  const navigate = useNavigate()

  return (
    <div className="newsletter">
      <div className="newsletter-icon">
        <img src={blogicon}></img>
      </div>
      <button className='newsletter-button ' onClick={()=>navigate('/blogform')}> write a blog </button>
     <p>Lorem ipsum or sample text of the printing and typesetting industry.</p>

    </div>
  )
}

export default Newsletter


import React from "react";
import Main from "./Layout";
import icon from "./../../images/ordersuccessful.png";
import "./OrderPlaced.css";
import { useNavigate } from "react-router-dom";

const OrderPlaced = () => {
	const navigate = useNavigate();
	return (
		<Main>
			<div className="order-placed-main">
				<img src={icon} alt="SheEagle Order Placed" />
				<h2>Thank you for your order!</h2>
				<p>
					Lorem Ipsum is simply dummy text of the printing and typesetting
					industry. Lorem Ipsum has been the industry's standard dummy text ever
					since the 1500s, when an unknown printer took a galley of type and
					scrambled it to make a type specimen book.
				</p>
				<div>
					<button onClick={() => navigate("/")}>Continue Shopping</button>
					<button onClick={() => navigate("/profile")}>Go to orders</button>
				</div>
			</div>
		</Main>
	);
};

export default OrderPlaced;

import React from "react";
import "./TermsAndConditions.css";
import Main from "../user/Layout";

const TermsAndConditions = () => {
	return (
		<Main>
			<div className="terms-container">
				<h1 className="terms-title">Terms & Conditions</h1>
				<div className="terms-content">
					<section>
						<h2>Welcome to Sheagle!</h2>
						<p>
							By accessing our website or placing an order, you agree to comply
							with these terms and conditions. Please read them carefully.
						</p>
						<ol>
							<li>
								<strong>Acceptance of Terms:</strong> By using our site, you
								confirm acceptance of these terms. If you disagree, please
								refrain from using our website.
							</li>
							<li>
								<strong>Account Registration:</strong> Creating an account may
								be required for purchases. You are responsible for maintaining
								the confidentiality of your account information. Report any
								unauthorized use immediately.
							</li>
							<li>
								<strong>Product Information:</strong> While we strive for
								accuracy in product descriptions and pricing, errors may occur.
								In such cases, we reserve the right to cancel or refuse orders
								placed at incorrect prices.
							</li>
							<li>
								<strong>Ordering and Payment:</strong> All orders are subject to
								acceptance and availability. Payment must be made at the time of
								order placement.
							</li>
							<li>
								<strong>Shipping and Delivery:</strong> Delivery times vary
								based on location and shipping method. Unforeseen delays like
								customs or weather may occur. International shipping may incur
								additional charges or duties.
							</li>
							<li>
								<strong>Returns and Exchanges:</strong> Refer to our Return and
								Exchange Policy for detailed instructions. All returns and
								exchanges must comply with the specified guidelines.
							</li>
							<li>
								<strong>Intellectual Property:</strong> Content on the Sheagle
								website—including text, graphics, logos, and images—is protected
								by copyright and trademark laws. Reproduction or distribution
								without permission is prohibited.
							</li>
							<li>
								<strong>Limitation of Liability:</strong> Sheagle is not liable
								for indirect, incidental, or consequential damages. Our
								liability is limited to the amount paid for the product.
							</li>
							<li>
								<strong>Governing Law:</strong> These terms are governed by the
								laws of [Your Jurisdiction]. Disputes will be resolved in the
								courts of that jurisdiction.
							</li>
							<li>
								<strong>Changes to Terms:</strong> We reserve the right to
								modify these terms. Updates will be posted on our website and
								take immediate effect.
							</li>
						</ol>
					</section>

					<section>
						<h2>Disclaimer</h2>

						<p>
							Sheagle strives to provide accurate and reliable information.
							However, the content on our site is for general purposes only, and
							we cannot guarantee its completeness or reliability.
						</p>

						<ul>
							<li>
								<strong>Product Descriptions:</strong> Details like pricing,
								colors, and styles may vary due to updates or screen settings.
								For questions, contact us directly.
							</li>
							<li>
								<strong>Pricing:</strong> Prices may change without notice.
								Orders placed at incorrect prices may be canceled or refused.
							</li>
							<li>
								<strong>Third-Party Links:</strong> We are not responsible for
								the content or practices of third-party websites linked on our
								site.
							</li>
							<li>
								<strong>Health and Safety:</strong> Health-related product
								claims are informational only and not a substitute for
								professional medical advice.
							</li>
							<li>
								<strong>Service:</strong> refers to the Website.
							</li>
							<li>
								<strong>Limitation of Liability:</strong> Sheagle is not liable
								for indirect damages, including lost data or profits, arising
								from the use of our site or products.
							</li>
							<li>
								<strong>Governing Law:</strong> This disclaimer is governed by
								the laws of [Your Jurisdiction]. Any disputes arising from this
								disclaimer shall fall under the exclusive jurisdiction of the
								courts in the specified jurisdiction. By using our website, you
								confirm that you have read, understood, and agree to be bound by
								the terms of this disclaimer. If you disagree, we kindly request
								that you refrain from using our website. Thank you for being a
								valued member of the Sheagle community! We deeply appreciate
								your trust in us and look forward to serving you.
							</li>
						</ul>
					</section>
				</div>
			</div>
		</Main>
	);
};

export default TermsAndConditions;

import { Collapse } from "antd";
import React from "react";
import Main from "./Layout";
import "./FAQ.css";

const FAQ = () => {
	const { Panel } = Collapse;
	const data = [
		{
			question: "What is Sheagle?",
			answer:
				"Welcome to Sheagle! We are an international e-commerce marketplace offering a diverse range of clothing and accessories, including bags, footwear, and artificial jewelry, designed to help you express your unique style.",
		},
		{
			question: "How do I place an order?",
			answer:
				"Placing an order is easy! Browse our website, select the items you wish to purchase, add them to your cart, and proceed to checkout. Follow the prompts to enter your shipping and payment information, and you’re all set!",
		},
		{
			question: "What payment methods do you accept?",
			answer:
				"We accept various secure payment methods, including credit/debit cards and PayPal, making shopping with us convenient.",
		},
		{
			question: "Do you ship internationally?",
			answer:
				"Absolutely! Sheagle proudly ships worldwide. Shipping charges may vary based on your location and chosen shipping method, so please check the details at checkout.",
		},
		{
			question: "How can I track my order?",
			answer:
				"Once your order ships, we’ll send you a confirmation email with a tracking number and a link to monitor your package. You can also track your order through your account on our website.",
		},
		{
			question: "What is your return and exchange policy?",
			answer:
				"Your satisfaction is our priority! If you need to exchange an item, simply cover the two-way shipping charges. For detailed instructions, please refer to our Return and Exchange Policy on our website.",
		},
		{
			question: "How do I contact customer support?",
			answer:
				"We’re here to help! Reach our customer support team through the Contact Us section on our website. We’re available via email, phone, or live chat and strive to respond promptly.",
		},
		{
			question: "Are there any discounts or promotions available?",
			answer:
				"Yes! We love sharing special promotions and discounts with our customers. Subscribe to our newsletter or check our website regularly for the latest offers.",
		},
		{
			question: "How do I create an account?",
			answer:
				"Creating an account is quick and easy! Click the Sign Up button on our website, fill in the required information, and follow the prompts. An account lets you track orders, save favorites, and enjoy a personalized shopping experience.",
		},
		{
			question: "What if I receive a damaged or incorrect item?",
			answer:
				"If you receive a damaged or incorrect item, contact our customer support team within 48 hours of receiving your order. We will work swiftly to resolve the issue and ensure your satisfaction.",
		},
		{
			question: "Do you offer gift cards?",
			answer:
				"Yes, we offer gift cards available for purchase on our website. They make the perfect gift for anyone who loves fashion and style.",
		},
		{
			question: "How can I unsubscribe from your newsletter?",
			answer:
				"To unsubscribe, click the Unsubscribe link at the bottom of any email or manage your preferences in your account settings.",
		},
		{
			question: "Can I change or cancel my order after placing it?",
			answer:
				"Once an order is confirmed, we process it immediately to ensure timely delivery. Therefore, cancellations are not possible. We appreciate your understanding.",
		},
		{
			question: "How do I know what size to order?",
			answer:
				"We provide detailed size charts for each product on our website. Refer to these charts and measure yourself to ensure the best fit. Contact our customer support team for assistance if needed.",
		},
		{
			question: "What if I forget my password?",
			answer:
				"No worries! Click the Forgot Password? link on the login page and follow the prompts to reset your password via the email associated with your account.",
		},
		{
			question: "Do you offer bulk purchasing options?",
			answer:
				"Yes, Sheagle offers bulk purchasing options for businesses and organizations. For more details, contact our customer support team or visit the Bulk Orders section on our website.",
		},
		{
			question: "What should I do if my package is lost or delayed?",
			answer:
				"If your package is lost or delayed, contact our customer support team. We’ll help track your package and resolve any shipping issues.",
		},
		{
			question: "Do you offer free shipping?",
			answer:
				"We occasionally run promotions offering free shipping on certain orders. Check our website or subscribe to our newsletter for updates on current offers.",
		},
		{
			question: "Is my personal information safe with you?",
			answer:
				"Yes, your privacy is very important to us. Sheagle implements strict security measures to protect your personal information and comply with all relevant privacy laws. For more details, refer to our Privacy Policy.",
		},
		{
			question: "Do you have a loyalty or rewards program?",
			answer:
				"While we don’t currently have a loyalty or rewards program, we are exploring ways to enhance your shopping experience. Stay tuned for updates!",
		},
		{
			question: "How can I stay updated on new arrivals and promotions?",
			answer:
				"Subscribe to our newsletter for updates on new arrivals, promotions, and exclusive offers. Follow us on social media for the latest news and trends.",
		},
		{
			question: "What should I do if I have a complaint or feedback?",
			answer:
				"We value your feedback! If you have complaints or suggestions, contact our customer support team. We take your concerns seriously and continually strive to improve based on customer input.",
		},
		{
			question: "Can I shop without creating an account?",
			answer:
				"Yes, you can shop as a guest. However, creating an account allows you to track your orders, save favorite items, and enjoy a personalized shopping experience.",
		},
	];
	return (
		<Main>
			<div className="faq-main-container">
				<Collapse
					expandIconPosition="end"
					bordered={false}
					className="faq-main-collapse">
					{data.map((item, index) => (
						<Panel
							header={item.question}
							key={index}
							className="faq-main-panel">
							<p className="faq-main-answer">{item.answer}</p>
						</Panel>
					))}
				</Collapse>
			</div>
		</Main>
	);
};

export default FAQ;

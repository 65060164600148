import React from "react";
import {
	FacebookFilled,
	TwitterSquareFilled,
	InstagramFilled,
	FacebookOutlined,
	InstagramOutlined,
} from "@ant-design/icons";
import logo from "./../../images/Homepage/logo.svg";

const FooterComponent = () => {
	return (
		<footer className="footer">
			<div className="footer-top">
				<div className="footer-content">
					<div className="footer-left">
						<img src={logo} alt="Storyland Logo" className="logo" />
						<p className="footer-description">
							Need help? Email us at info@sheagle.com. We're here for you, and
							ready to answer your questions.
						</p>
						<div className="social-media">
							<a
								href="https://www.facebook.com/"
								target="_blank"
								rel="noopener noreferrer"
								className="social-icon">
								<FacebookFilled />
							</a>
							<a
								href="https://www.instagram.com/"
								target="_blank"
								rel="noopener noreferrer"
								className="social-icon">
								<InstagramOutlined />
							</a>
						</div>
					</div>
					<div className="footer-middle">
						<h4>Customer Care</h4>
						<ul className="quick-links">
							{/* <li>
								<a href="/sizeguide">Size Guide</a>
							</li> */}
							<li>
								<a href="/shippinginfo">Shipping Information</a>
							</li>
							<li>
								<a href="/returns-exchange">Returns & Exchanges</a>
							</li>
							<li>
								<a href="/contact">Contact Us</a>
							</li>
						</ul>
					</div>

					<div className="footer-middle">
						<h4>Supplies</h4>
						<ul className="quick-links">
							<li>
								<a href="/men">Men</a>
							</li>
							<li>
								<a href="/women">Women</a>
							</li>
							<li>
								<a href="/kids">Kids</a>
							</li>
							<li>
								<a href="/beauty">Beauty</a>
							</li>
						</ul>
					</div>

					<div className="footer-middle">
						<h4>Company</h4>
						<ul className="quick-links">
							<li>
								<a href="/about">About us</a>
							</li>
							<li>
								<a href="/vendor">Vendor</a>
							</li>
							<li>
								<a href="/vendor/signup">Sign up as Vendor</a>
							</li>
							<li>
								<a href="/terms-conditions">Terms & Conditions</a>
							</li>
						</ul>
					</div>

					<div className="footer-middle">
						<h4>Policies</h4>
						<ul className="quick-links">
							<li>
								<a href="/policies/privacy">Privacy Policy</a>
							</li>
							<li>
								<a href="/policies/return">Return Policy</a>
							</li>
							<li>
								<a href="/policies/cancellation">Cancellation Policy</a>
							</li>
							<li>
								<a href="/policies/refund">Refund Policy</a>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="footer-bottom">
				<p className="copyright">
					Copyright © 2024, <span>SHEagle Trading</span> - All Rights Reserved.
				</p>
				<p className="gads">
					Designed and Developed by{" "}
					<a
						href="https://gadigitalsolutions.com"
						target="_blank"
						rel="noopener noreferrer">
						GA Digital Solutions
					</a>
				</p>
			</div>
		</footer>
	);
};

export default FooterComponent;

import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import "./SpecificProduct.css";
import Main from "./Layout";
import { Breadcrumb, Button, Image, message, Progress, Rate, Tabs } from "antd";
import truck from "./../../images/Products/truck-time.png";
import payments from "./../../images/Products/payments.png";
import shipping from "./../../images/Products/Transportation.png";
import returns from "./../../images/Products/return.png";
import support from "./../../images/Products/support.png";
import CartIcon from "./CartIcon";
import TabPane from "antd/es/tabs/TabPane";

const SpecificProduct = () => {
	const { id } = useParams();
	const { apiurl, token, currentCountry } = useAuth();
	const [productdetails, setProductDetails] = useState(null);
	const [selectedColor, setSelectedColor] = useState(null);
	const [selectedSize, setSelectedSize] = useState(null);
	const [filteredVariants, setFilteredVariants] = useState([]);
	const [availableColors, setAvailableColors] = useState([]);
	const [availableSizes, setAvailableSizes] = useState([]);
	const [quantity, setQuantity] = useState(1);
	const [count, setCount] = useState(0);
	const [isAddedtoCart, setIsAddedtoCart] = useState(false);
	const [reviews, setReviews] = useState([]);
	const [price, setPrice] = useState(0);
	const [discountedPrice, setDiscountedPrice] = useState(0);
	const [coupons, setCoupons] = useState([]); // Coupons state
	const [selectedCoupon, setSelectedCoupon] = useState(null);
	const navigate = useNavigate();
	const [filteredReviews, setFilteredReviews] = useState(reviews);
	const [filter, setFilter] = useState("all");
	const [sortOrder, setSortOrder] = useState("recent");

	const fetchProductDetails = async () => {
		const response = await fetch(`${apiurl}/products/${id}`);
		const data = await response.json();
		setProductDetails(data);
		setCoupons(data.coupons);
		setSelectedColor(data.product_varients[0].color?.name);
		setSelectedSize(data.product_varients[0].size);
		setFilteredVariants(data.product_varients);
		setAvailableColors([
			...new Set(data.product_varients.map((variant) => variant.color?.name)),
		]);
	};

	const fetchCart = async () => {
		try {
			const response = await fetch(`${apiurl}/cart/`, {
				method: "GET",
				headers: {
					Authorization: "Bearer " + token,
				},
			});
			const data = await response.json();
			setCount(data.items.length);
		} catch {
			console.log("Failed to fetch cart");
		}
	};

	useEffect(() => {
		if (productdetails && selectedColor) {
			const filteredByColor = productdetails.product_varients.filter(
				(variant) => variant.color.name === selectedColor
			);
			setFilteredVariants(filteredByColor);

			const uniqueSizes = [
				...new Set(filteredByColor.map((variant) => variant.size)),
			];
			setAvailableSizes(uniqueSizes);

			setSelectedSize(filteredByColor[0]?.size);
		}
	}, [selectedColor, productdetails]);

	const fetchReviews = async () => {
		const response = await fetch(`${apiurl}/reviews/product/${id}`);
		const data = await response.json();
		setReviews(data);
	};

	useEffect(() => {
		fetchProductDetails();
		fetchCart();
		fetchReviews();
	}, []);

	const filterByRating = (rating) => {
		return reviews.filter((review) => review.rating === rating);
	};

	const filterWithImages = () => {
		return reviews.filter((review) => review.images.length > 0);
	};

	const sortReviews = (reviews, order) => {
		switch (order) {
			case "recent":
				return reviews.sort(
					(a, b) => new Date(b.created_at) - new Date(a.created_at)
				);
			case "rating_desc":
				return reviews.sort((a, b) => b.rating - a.rating);
			case "rating_asc":
				return reviews.sort((a, b) => a.rating - b.rating);
			default:
				return reviews;
		}
	};

	useEffect(() => {
		let filtered = [...reviews];

		if (filter !== "all") {
			filtered = filterByRating(parseInt(filter));
		}

		if (filter === "with_images") {
			filtered = filterWithImages();
		}

		filtered = sortReviews(filtered, sortOrder);

		setFilteredReviews(filtered);
	}, [filter, sortOrder, reviews]);

	useEffect(() => {
		if (filteredVariants.length === 0) return;

		const selectedVariant = filteredVariants.find(
			(variant) =>
				variant.color?.name === selectedColor && variant.size === selectedSize
		);

		if (selectedVariant) {
			const countryName = currentCountry.name;
			const countryPrice = selectedVariant.country_specific_prices.find(
				(priceInfo) => priceInfo.country_name === countryName
			)?.price;
			const countryDiscountType = selectedVariant.country_specific_prices.find(
				(priceInfo) => priceInfo.country_name === countryName
			)?.discount_type;
			const countryDiscount = selectedVariant.country_specific_prices.find(
				(priceInfo) => priceInfo.country_name === countryName
			)?.discount;

			console.log(countryDiscount);

			let discountPrice = 0;
			setPrice(countryPrice || selectedVariant.price);
			if (countryDiscountType === "Amount") {
				discountPrice = parseFloat(price) - parseFloat(countryDiscount);
			} else {
				discountPrice =
					parseFloat(price) -
					(parseFloat(price) * parseFloat(countryDiscount)) / 100;
			}

			setDiscountedPrice(discountPrice || price);
		}
	}, [selectedColor, selectedSize, filteredVariants, currentCountry]);

	if (!productdetails) {
		return <div>Loading...</div>;
	}

	const { name, product_varients } = productdetails;
	const selectedVariant = filteredVariants.find(
		(variant) =>
			variant.color?.name === selectedColor && variant?.size === selectedSize
	);

	const totalPrice = selectedVariant ? price * quantity : 0;
	
	const totalDiscount = selectedVariant ? discountedPrice * quantity : 0;

	const handleSizeClick = (size) => {
		if (availableSizes.includes(size)) {
			setSelectedSize(size);
		}
	};

	const handleAddToCart = async () => {
		if (!token) {
			return message.error("Please login to add item to cart.");
		}
		const response = await fetch(`${apiurl}/cart/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				item_id: selectedVariant.id,
				quantity: quantity,
			}),
		});
		if (response.ok) {
			message.success("Product Added to cart successfully");
			fetchCart();
			setIsAddedtoCart(true);
		}
	};

	const formatDate = (date) => {
		return new Date(date).toLocaleDateString();
	};

	const getRatingCount = (rating) => {
		return reviews.filter(
			(review) => parseInt(review.rating) === parseInt(rating)
		).length;
	};

	const renderRatingRow = (rating, count, totalReviews) => {
		const percentage = totalReviews ? (count / totalReviews) * 100 : 0;

		return (
			<div
				style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
				key={rating}>
				{rating}
				<Rate
					disabled
					defaultValue={rating}
					count={1}
					style={{ fontSize: 16, color: "#34cf0b", marginLeft: 8 }}
				/>

				<div style={{ width: 160, marginLeft: 16 }}>
					<Progress
						percent={percentage.toFixed(1)}
						showInfo={false}
						strokeColor={"#34cf0b"}
					/>
				</div>

				<span style={{ marginLeft: 8 }}>{count}</span>
			</div>
		);
	};

	// const handleApplyCoupon = (coupon) => {
	// 	if (coupon) {
	// 		setSelectedCoupon(coupon);
	// 		const discountAmount =
	// 			coupon.type === "percentage"
	// 				? (totalPrice * coupon.value) / 100
	// 				: coupon.value;

	// 		const newPrice = Math.max(0, totalPrice - discountAmount);
	// 		setDiscountedPrice(newPrice);

	// 		message.success(`Coupon ${coupon.code} applied successfully!`);
	// 	}
	// };

	// const handleRemoveCoupon = () => {
	// 	setSelectedCoupon(null);
	// 	setDiscountedPrice(totalPrice);
	// 	message.success("Coupon removed.");
	// };

	return (
		<Main>
			<div className="specific-product-main">
				<div className="breadcrumb-container">
					<Breadcrumb>
						<Breadcrumb.Item>
							<Link to="/">Home</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<Link to={`/products`}>Products</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>{name}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div className="specific-product">
					<div className="specific-product-left">
						<div className="specific-product-image">
							{filteredVariants.length > 0 &&
							filteredVariants[0].images.length > 0 ? (
								<img
									src={apiurl + filteredVariants[0].images[0].image}
									alt="Product"
								/>
							) : (
								<img
									src={apiurl + product_varients[0]?.images[0]?.image}
									alt="Product"
								/>
							)}
						</div>

						<div className="specific-product-thumbnails">
							{filteredVariants.length > 0 &&
								filteredVariants[0].images.map((image, index) => (
									<img
										key={index}
										src={apiurl + image.image}
										alt={`Thumbnail ${index}`}
										className="thumbnail-image"
										onClick={() => {
											const mainImage = document.querySelector(
												".specific-product-image img"
											);
											mainImage.src = apiurl + image.image;
										}}
									/>
								))}
						</div>
					</div>

					<div className="specific-product-right">
						<h1 className="specific-product-title">{name}</h1>
						{selectedColor && availableColors.length > 0 && (
							<div className="specific-product-color">
								<label>
									Color: <span>{selectedColor}</span>
								</label>
								<div className="color-selection">
									{availableColors.map((color) => (
										<button
											key={color}
											className={`color-button ${
												color === selectedColor ? "selected" : ""
											}`}
											onClick={() => setSelectedColor(color)}>
											{color}
										</button>
									))}
								</div>
							</div>
						)}
						{availableSizes.length > 0 && (
							<div className="specific-product-size">
								<label>
									Size: <span>{selectedSize}</span>
								</label>
								<div className="size-selection">
									{availableSizes.map((size) => (
										<button
											key={size}
											className={`size-button ${
												size === selectedSize ? "selected" : ""
											}`}
											onClick={() => handleSizeClick(size)}>
											{size}
										</button>
									))}
								</div>
							</div>
						)}
						{/* <div className="coupons">
							<div className="coupon-section">
								<h3>Available Coupons</h3>
								<div className="coupon-list">
									{coupons.map((coupon) => (
										<div key={coupon.id} className="coupon-card">
											<p>
												<strong>{coupon.code}</strong> -{" "}
												{coupon.type === "percentage"
													? `${coupon.value}% off`
													: `Flat ${currentCountry.symbol}${coupon.value} off`}
											</p>
											{selectedCoupon && selectedCoupon.id === coupon.id ? (
												<Button
													danger
													type="text"
													onClick={() => handleRemoveCoupon(coupon)}>
													Remove
												</Button>
											) : (
												<Button
													className="applybtn"
													type="text"
													onClick={() => handleApplyCoupon(coupon)}>
													Apply
												</Button>
											)}
										</div>
									))}
								</div>
							</div>
						</div> */}
						<div className="quantity-cart-container">
							{!isAddedtoCart ? (
								<>
									<div className="specific-product-quantity">
										<label>Quantity</label>
										<div className="quantity-selector">
											<button
												onClick={() =>
													setQuantity(quantity > 1 ? quantity - 1 : 1)
												}>
												-
											</button>
											<input
												type="number"
												value={quantity}
												onChange={(e) =>
													setQuantity(parseInt(e.target.value, 10) || 1)
												}
											/>
											<button onClick={() => setQuantity(quantity + 1)}>
												+
											</button>
										</div>
									</div>
									<div className="specific-product-price">
										{selectedVariant ? (
											<button
												className="specific-product-add-to-cart"
												onClick={handleAddToCart}>
												{totalPrice == totalDiscount ? (
													<span className="price">
														{currentCountry.symbol} {totalPrice}
													</span>
												) : (
													<>
														<span className="total-price original-price">
															{currentCountry.symbol} {totalPrice}
														</span>
														<span className="discounted-price">
															{currentCountry.symbol} {totalDiscount}
														</span>
													</>
												)}{" "}
												- Add to Cart
											</button>
										) : (
											<p>Not available</p>
										)}
									</div>
								</>
							) : (
								<button
									className="specific-product-add-to-cart"
									onClick={() => navigate("/cart")}>
									Go to Cart
								</button>
							)}
						</div>

						<div className="specific-product-delivery">
							<img src={truck} alt="truck" />
							<p>Estimated Delivery: 15 Dec 2023 - 23 Dec 2023</p>
						</div>

						<div className="product-meta">
							<div className="meta-item">
								<img src={shipping} alt="shipping" />
								<p>
									Easy <br />
									Shipping
								</p>
							</div>
							<div className="meta-item">
								<img src={returns} alt="returns" />
								<p>
									Easy <br /> Returns
								</p>
							</div>
							<div className="meta-item">
								<img src={support} alt="support" />
								<p>
									Support <br /> 24*7
								</p>
							</div>
							<div className="meta-item">
								<img src={payments} alt="payments" />
								<p>
									Secure <br /> Payments
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="product-description-main">
					<Tabs>
						<TabPane tab="Description" key="1">
							<div className="product-description">
								{productdetails.description}
							</div>
						</TabPane>
						<TabPane tab="Size Guide" key="2">
							<div className="product-description">Size Guide</div>
						</TabPane>
					</Tabs>
				</div>
				<div className="spproduct-reviews-main">
					<div className="left-sec">
						<h2>Customer Reviews</h2>
						<div className="avg-reviews">
							<h1>{productdetails.avg_rating}</h1>
							<div>
								<Rate
									disabled
									defaultValue={productdetails.avg_rating}
									count={5}
									style={{ fontSize: 16, color: "#34cf0b" }}
								/>
								<p>
									Based on {productdetails.total_reviews}{" "}
									{productdetails.total_reviews === 1 ? "review" : "reviews"}
								</p>
							</div>
						</div>
						<div className="reviews-stats">
							{Array.from({ length: 5 }, (_, i) => {
								const rating = 5 - i;
								return renderRatingRow(
									rating,
									getRatingCount(rating),
									reviews.length
								);
							})}
						</div>
					</div>

					<div className="filter-grid">
						<div className="filters">
							<div>
								<select
									onChange={(e) => setFilter(e.target.value)}
									value={filter}>
									<option value="all">All reviews</option>
									<option value="5">5 Stars</option>
									<option value="4">4 Stars</option>
									<option value="3">3 Stars</option>
									<option value="2">2 Stars</option>
									<option value="1">1 Star</option>
									<option value="with_images">With Images</option>
								</select>
							</div>

							<div>
								<select
									onChange={(e) => setSortOrder(e.target.value)}
									value={sortOrder}>
									<option value="recent">Most recent</option>
									<option value="rating_desc">Rating: High to Low</option>
									<option value="rating_asc">Rating: Low to High</option>
								</select>
							</div>
						</div>
						{filteredReviews.length > 0 ? (
							<div className="product-reviews">
								{filteredReviews.map((review) => (
									<div key={review.id} className="review">
										<div className="review-header">
											<div>
												<h4>{review.user}</h4>
												<Rate
													disabled
													value={review.rating}
													style={{ fontSize: 16, color: "#bbb" }}
												/>
												<span style={{ fontSize: 11 }}>
													{" "}
													{formatDate(review.created_at)}
												</span>
											</div>
										</div>
										<p>{review.review}</p>
										<div className="review-images">
											{review.images.map((image, index) => (
												<Image
													key={index}
													src={apiurl + image.image}
													alt="review"
												/>
											))}
										</div>
									</div>
								))}
							</div>
						) : (
							<p>No reviews yet</p>
						)}
					</div>
				</div>
			</div>

			
		</Main>
	);
};

export default SpecificProduct;

import React from "react";
import Main from "../user/Layout";

const ReturnPolicy = () => {
	return (
		<Main>
			<div className="terms-container">
				<h1 className="terms-title">Return Policy</h1>

				<div className="terms-content">
					<p>
						This Privacy Policy describes Our policies and procedures on the
						collection, use, and disclosure of Your information when You use the
						Service and tells You about Your privacy rights and how the law
						protects You.
					</p>
					<p>
						We use Your Personal data to provide and improve the Service. By
						using the Service, You agree to the collection and use of
						information in accordance with this Privacy Policy.
					</p>

					<section>
						<h2>Interpretation and Definitions</h2>
						<p>
							<strong>Interpretation:</strong> The words of which the initial
							letter is capitalized have meanings defined under the following
							conditions. The following definitions shall have the same meaning
							regardless of whether they appear in singular or plural.
						</p>
						<p>
							<strong>Definitions:</strong> For the purposes of this Privacy
							Policy:
						</p>
						<ul>
							<li>
								<strong>Account</strong> means a unique account created for You
								to access our Service or parts of our Service.
							</li>
							<li>
								<strong>Affiliate</strong> means an entity that controls, is
								controlled by, or is under common control with a party, where
								“control” means ownership of 50% or more of the shares, equity
								interest, or other securities entitled to vote for election of
								directors or other managing authority.
							</li>
							<li>
								<strong>Company</strong> (referred to as either “the Company,”
								“We,” “Us,” or “Our” in this Agreement) refers to Sheagle, Plot
								No. 48, 3rd Floor, Malla Reddy Nagar, Gajularamaram,
								Hyderabad-500055.
							</li>
							<li>
								<strong>Cookies</strong> are small files that are placed on Your
								computer, mobile device, or any other device by a website,
								containing the details of Your browsing history on that website
								among its many uses.
							</li>
							<li>
								<strong>Country</strong> refers to: Telangana, India
							</li>
							<li>
								<strong>Device</strong> means any device that can access the
								Service such as a computer, a cellphone, or a digital tablet.
							</li>
							<li>
								<strong>Personal Data</strong> is any information that relates
								to an identified or identifiable individual.
							</li>
							<li>
								<strong>Service</strong> refers to the Website.
							</li>
							<li>
								<strong>Service Provider</strong> means any natural or legal
								person who processes the data on behalf of the Company. It
								refers to third-party companies or individuals employed by the
								Company to facilitate the Service, to provide the Service on
								behalf of the Company, to perform services related to the
								Service or to assist the Company in analyzing how the Service is
								used.
							</li>
							<li>
								<strong>Usage Data</strong> refers to data collected
								automatically, either generated by the use of the Service or
								from the Service infrastructure itself (for example, the
								duration of a page visit).
							</li>
							<li>
								<strong>Website</strong> refers to sheagle.online, accessible
								from{" "}
								<a href="https://sheagle.online">https://sheagle.online/</a>
							</li>
							<li>
								<strong>You</strong> means the individual accessing or using the
								Service, or the company, or other legal entity on behalf of
								which such individual is accessing or using the Service, as
								applicable.
							</li>
						</ul>
					</section>

					<section>
						<h2>Collecting and Using Your Personal Data</h2>
						<h3>Types of Data Collected</h3>
						<h4>Personal Data</h4>
						<p>
							While using Our Service, We may ask You to provide Us with certain
							personally identifiable information that can be used to contact or
							identify You. Personally identifiable information may include, but
							is not limited to:
						</p>
						<ul>
							<li>Email address</li>
							<li>First name and last name</li>
							<li>Phone number</li>
							<li>Address, State, Province, ZIP/Postal code, City</li>
						</ul>

						<h4>Usage Data</h4>
						<p>Usage Data is collected automatically when using the Service.</p>
						<p>
							Usage Data may include information such as Your Device’s Internet
							Protocol address (e.g. IP address), browser type, browser version,
							the pages of our Service that You visit, the time and date of Your
							visit, the time spent on those pages, unique device identifiers,
							and other diagnostic data.
						</p>

						<h3>Tracking Technologies and Cookies</h3>
						<p>
							We use Cookies and similar tracking technologies to track the
							activity on Our Service and store certain information. Tracking
							technologies used are beacons, tags, and scripts to collect and
							track information and to improve and analyze Our Service. The
							technologies We use may include:
						</p>
						<ul>
							<li>
								Cookies or Browser Cookies. A cookie is a small file placed on
								Your Device. You can instruct Your browser to refuse all Cookies
								or to indicate when a Cookie is being sent. However, if You do
								not accept Cookies, You may not be able to use some parts of our
								Service.
							</li>
							<li>
								Web Beacons. Certain sections of our Service and our emails may
								contain small electronic files known as web beacons (also
								referred to as clear gifs, pixel tags, and single-pixel gifs)
								that permit the Company, for example, to count users who have
								visited those pages or opened an email and for other related
								website statistics (for example, recording the popularity of a
								certain section and verifying system and server integrity).
							</li>
						</ul>
					</section>

					<section>
						<h2>Use of Your Personal Data</h2>
						<p>The Company may use Personal Data for the following purposes:</p>
						<ul>
							<li>
								To provide and maintain our Service, including to monitor the
								usage of our Service.
							</li>
							<li>
								To manage Your Account: to manage Your registration as a user of
								the Service. The Personal Data You provide can give You access
								to different functionalities of the Service that are available
								to You as a registered user.
							</li>
							<li>
								For the performance of a contract: the development, compliance,
								and undertaking of the purchase contract for the products,
								items, or services You have purchased or of any other contract
								with Us through the Service.
							</li>
							<li>
								To contact You: To contact You by email, telephone calls, SMS,
								or other equivalent forms of electronic communication, such as a
								mobile application’s push notifications regarding updates or
								informative communications related to the functionalities,
								products, or contracted services, including the security
								updates, when necessary or reasonable for their implementation.
							</li>
							<li>
								To provide You with news, special offers and general information
								about other goods, services and events which we offer that are
								similar to those that you have already purchased or enquired
								about unless You have opted not to receive such information.
							</li>
							<li>
								To manage Your requests: To attend and manage Your requests to
								Us.
							</li>
						</ul>
					</section>

					<section>
						<h2>Retention of Your Personal Data</h2>
						<p>
							The Company will retain Your Personal Data only for as long as is
							necessary for the purposes set out in this Privacy Policy. We will
							retain and use Your Personal Data to the extent necessary to
							comply with our legal obligations (for example, if we are required
							to retain your data to comply with applicable laws), resolve
							disputes, and enforce our legal agreements and policies.
						</p>
					</section>

					<section>
						<h2>Transfer of Your Personal Data</h2>
						<p>
							Your information, including Personal Data, is processed at the
							Company’s operating offices and in any other places where the
							parties involved in the processing are located. It means that this
							information may be transferred to — and maintained on — computers
							located outside of Your state, province, country, or other
							governmental jurisdiction where the data protection laws may
							differ from those of Your jurisdiction.
						</p>
					</section>

					<section>
						<h2>Delete Your Personal Data</h2>
						<p>
							You have the right to delete or request that We assist in deleting
							the Personal Data that We have collected about You.
						</p>
						<p>
							Our Service may give You the ability to delete certain information
							about You from within the Service. You may also contact Us to
							request access to, correct, or delete any personal information
							that You have provided to Us.
						</p>
					</section>

					<section>
						<h2>Disclosure of Your Personal Data</h2>
						<p>
							We may share Your personal information in the following
							situations:
						</p>
						<ul>
							<li>
								With Service Providers: We may share Your personal information
								with Service Providers to monitor and analyze the use of our
								Service, to contact You.
							</li>
							<li>
								For business transfers: We may share or transfer Your Personal
								Data in connection with, or during negotiations of, any merger,
								sale of Company assets, financing, or acquisition of all or a
								portion of Our business to another company.
							</li>
							<li>
								With Affiliates: We may share Your information with Our
								affiliates, in which case we will require those affiliates to
								honor this Privacy Policy.
							</li>
							<li>
								With business partners: We may share Your information with Our
								business partners to offer You certain products, services, or
								promotions.
							</li>
							<li>
								With other users: When You share personal information or
								interact in public areas with other users, such information may
								be viewed by all users and may be publicly distributed outside.
							</li>
						</ul>
					</section>

					<section>
						<h2>Security of Your Personal Data</h2>
						<p>
							The security of Your Personal Data is important to Us, but
							remember that no method of transmission over the Internet, or
							method of electronic storage, is 100% secure. While We strive to
							use commercially acceptable means to protect Your Personal Data,
							We cannot guarantee its absolute security.
						</p>
					</section>

					<section>
						<h2>Your Data Protection Rights</h2>
						<p>
							Depending on your location, you may have the following data
							protection rights:
						</p>
						<ul>
							<li>
								<strong>The Right to Access</strong> – You have the right to
								request copies of your personal data.
							</li>
							<li>
								<strong>The Right to Rectification</strong> – You have the right
								to request that we correct any information you believe is
								inaccurate or complete information you believe is incomplete.
							</li>
							<li>
								<strong>The Right to Erasure</strong> – You have the right to
								request that we erase your personal data, under certain
								conditions.
							</li>
							<li>
								<strong>The Right to Restrict Processing</strong> – You have the
								right to request that we restrict the processing of your
								personal data, under certain conditions.
							</li>
							<li>
								<strong>The Right to Object to Processing</strong> – You have
								the right to object to our processing of your personal data,
								under certain conditions.
							</li>
							<li>
								<strong>The Right to Data Portability</strong> – You have the
								right to request that we transfer the data that we have
								collected to another organization, or directly to you, under
								certain conditions.
							</li>
						</ul>
						<p>
							If you make a request, we have one month to respond to you. If you
							would like to exercise any of these rights, please contact us at
							our provided contact information.
						</p>
					</section>

					<section>
						<h2>Changes to This Privacy Policy</h2>
						<p>
							We may update our Privacy Policy from time to time. We will notify
							you of any changes by posting the new Privacy Policy on this page.
							You are advised to review this Privacy Policy periodically for any
							changes. Changes to this Privacy Policy are effective when they
							are posted on this page.
						</p>
					</section>
				</div>
			</div>
		</Main>
	);
};

export default ReturnPolicy;

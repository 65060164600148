import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
	MenuOutlined,
	UserOutlined,
	ShoppingCartOutlined,
	HeartOutlined,
	SearchOutlined,
	BehanceCircleFilled,
} from "@ant-design/icons";
import { Layout, Menu, Drawer, Button, Input, Dropdown, Result } from "antd";
import "./Layout.css";
import logo from "./../../images/Homepage/logo.svg";
import sheaglefashion from "./../../images/Homepage/sheaglefashion.svg";
import sheaglebtb from "./../../images/Homepage/sheaglebtb.svg";
import sheagleuniforms from "./../../images/Homepage/sheagleuniform.svg";
import switchicon from "./../../images/Homepage/switchicon.svg";
import loginbtn from "./../../images/Homepage/loginbtn.svg";
import signupbtn from "./../../images/Homepage/icons/Login.svg";
import becuser from "./../../images/Homepage/icons/Become a Seller.svg";
import becvendor from "./../../images/Homepage/icons/Sign up as vendor.svg";
import menuicon from "./../../images/Homepage/Menu.svg";
import FooterComponent from "./Footer";
import { useAuth } from "../utils/useAuth";
import countries from "../utils/Countries";
import CartIcon from "./CartIcon";

const { Header } = Layout;

const Main = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [storeDrawerVisible, setStoreDrawerVisible] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const { apiurl, token, currentCountry = {}, setCountry, role } = useAuth();
	const [catgroups, setCatgroups] = useState([]);
	const [openKeys, setOpenKeys] = useState([]);
	const [count, setCount] = useState(0);

	const groupCategoriesByMain = () => {
		return catgroups.reduce((acc, item) => {
			if (!acc[item.maincategory]) {
				acc[item.maincategory] = [];
			}
			acc[item.maincategory].push(item);
			return acc;
		}, {});
	};

	useEffect(() => {
		const fetchcatgroups = async () => {
			const response = await fetch(`${apiurl}/headercats/`);
			const data = await response.json();
			setCatgroups(data);
		};

		fetchcatgroups();
		fetchCart();
	}, [apiurl]);

	const groupedCategories = groupCategoriesByMain();

	const handleCountryChange = (country) => {
		setCountry(JSON.stringify(country));
		window.location.reload();
	};

	const storeMenuItems = [
		{
			key: "1",
			label: (
				<Link to="/">
					<img src={sheaglefashion} alt="she-eagle-fashion" />
				</Link>
			),
		},
		{
			key: "2",
			label: (
				<Link to="/sheagleuniform">
					<img src={sheagleuniforms} alt="she-eagle-uniform" />
				</Link>
			),
		},
		{
			key: "3",
			label: (
				<Link to="/sheaglebtb">
					<img src={sheaglebtb} alt="she-eagle-btb" />
				</Link>
			),
		},
	];

	const handleSearch = () => {
		if (searchValue.trim()) {
			navigate(`/products?search=${encodeURIComponent(searchValue.trim())}`);
		}
	};

	const defaultSelectedKeys = () => {
		const pathname = location.pathname;
		const menuItems = [
			"/",
			"/men",
			"/women",
			"/kids",
			"/beauty",
			"/blogs",
			"/faq",
			"/search",
		];
		const index = menuItems.findIndex((item) => pathname === item);
		return index !== -1 ? `${index}` : "0";
	};

	const countryMenu = {
		items: countries.map((country) => ({
			key: country.code,
			label: (
				<div
					onClick={() => handleCountryChange(country)}
					style={{ display: "flex", alignItems: "center", gap: "8px" }}>
					<img
						src={`https://flagcdn.com/16x12/${country.code.toLowerCase()}.png`}
						alt={`${country.name} flag`}
					/>
					<span>
						{country.name} ({country.currency})
					</span>
				</div>
			),
		})),
	};

	const getProfileNavigateLink = () => {
		if (role === "admin") {
			return "/admin/home";
		} else if (role === "vendor") {
			return "/vendor/home";
		} else {
			return "/profile?tab=profile";
		}
	};

	const renderMegaMenu = (mainCategory) => {
		const categories = groupedCategories[mainCategory] || [];
		if (categories.length === 0) {
			return (
				<Result
					status="info"
					icon={false}
					title={`No items available in ${mainCategory}`}
					subTitle="Please check back later or explore other categories."
				/>
			);
		}
		return (
			<div className="mega-menu">
				<>
					{categories.map((category) => (
						<>
							<Link
								to={"/products/" + category.navigationUrl}
								className="category-link">
								{category.name}
							</Link>

							{category.subcategories.length > 0 && (
								<>
									{category.subcategories.map((subcat) => (
										<li key={subcat.id} className="sub-cat-link">
											<Link to={"/products/" + subcat.navigationUrl}>
												{subcat.name}
											</Link>
										</li>
									))}
								</>
							)}
						</>
					))}
				</>
			</div>
		);
	};

	const loginDropdownMenu = {
		items: [
			{
				key: "1",
				label: (
					<Link to="/login">
						<span className="login-dp-cont">
							<img src={signupbtn} alt="login"></img>Login
						</span>
					</Link>
				),
			},
			{
				key: "2",
				label: (
					<Link to="/vendor/signup">
						<span className="login-dp-cont">
							<img src={becvendor} alt="login"></img>Sign Up as Seller
						</span>
					</Link>
				),
			},
			{
				key: "3",
				label: (
					<Link to="/signup">
						<span className="login-dp-cont">
							<img src={becuser} alt="login"></img>Sign Up as Buyer
						</span>
					</Link>
				),
			},
		],
	};

	const menuItems = [
		{
			key: "1",
			label: (
				<div className="menu-item-with-mega">
					<Link to="/men">Men</Link>
					<div className="mega-menu-container">{renderMegaMenu("MEN")}</div>
				</div>
			),
		},
		{
			key: "2",
			label: (
				<div className="menu-item-with-mega">
					<Link to="/women">Women</Link>
					<div className="mega-menu-container">{renderMegaMenu("WOMEN")}</div>
				</div>
			),
		},
		{
			key: "3",
			label: (
				<div className="menu-item-with-mega">
					<Link to="/kids">Kids</Link>
					<div className="mega-menu-container">{renderMegaMenu("KIDS")}</div>
				</div>
			),
		},
		{
			key: "4",
			label: (
				<div className="menu-item-with-mega">
					<Link to="/beauty">Beauty</Link>
					<div className="mega-menu-container">
						{renderMegaMenu("BEAUTY PRODUCTS")}
					</div>
				</div>
			),
		},
		{
			key: "5",
			label: <Link to="/blogs">Blog</Link>,
		},
		{
			key: "6",
			label: <Link to="/faq">FAQ</Link>,
		},
		{
			key: "7",
			label: (
				<Input
					placeholder="Search By Product"
					prefix={<SearchOutlined />}
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					onPressEnter={handleSearch}
				/>
			),
		},
		...(token
			? [
					...(role === "user"
						? [
								{
									key: "8",
									label: (
										<Link to="/cart">
											<ShoppingCartOutlined />
										</Link>
									),
								},
								{
									key: "9",
									label: (
										<Link to="/profile?tab=wishlist">
											<HeartOutlined />
										</Link>
									),
								},
						  ]
						: []),
					{
						key: "10",
						label: (
							<Link to={getProfileNavigateLink()}>
								<UserOutlined />
							</Link>
						),
					},
					{
						key: "11",
						label: (
							<div className="country-flag">
								<span>{currentCountry.flag}</span>
								<img
									src={`https://flagcdn.com/w40/${currentCountry.code.toLowerCase()}.png`}
									alt="sheagle-cc"
									width={"24px"}
								/>
							</div>
						),
					},
			  ]
			: [
					{
						key: "13",
						label: (
							<Dropdown menu={countryMenu} trigger={["click"]}>
								<div className="country-flag">
									<span>{currentCountry.flag}</span>
									<img
										src={`https://flagcdn.com/w40/${currentCountry.code.toLowerCase()}.png`}
										alt="sheagle-cc"
										width={"24px"}
									/>
								</div>
							</Dropdown>
						),
					},
					{
						key: "14",
						label: (
							<Dropdown
								menu={loginDropdownMenu}
								trigger={["hover"]}
								className="login-drop-down">
								<div className="user-login-head">
									<img src={loginbtn} alt="sheagle-login" />
								</div>
							</Dropdown>
						),
					},
			  ]),
	];

	const mobilemenuItems = [
		...(token
			? []
			: [
					{
						key: "11",
						label: (
							<Dropdown
								menu={loginDropdownMenu}
								trigger={["hover"]}
								className="login-drop-down">
								<div className="user-login-head">
									<img src={loginbtn} alt="sheagle-login" />
								</div>
							</Dropdown>
						),
					},
					{
						key: "10",
						label: (
							<Dropdown menu={countryMenu} trigger={["click"]}>
								<div className="country-flag">
									Region : <span></span>
									<img
										src={`https://flagcdn.com/w40/${currentCountry.code.toLowerCase()}.png`}
										alt="sheagle-cc"
										width={"24px"}
									/>
								</div>
							</Dropdown>
						),
					},
			  ]),
		{
			key: "1",
			label: (
				<div className="menu-item-with-mega">
					<Link to="/men">Men</Link>
					<div className="mega-menu-container">{renderMegaMenu("MEN")}</div>
				</div>
			),
		},
		{
			key: "2",
			label: (
				<div className="menu-item-with-mega">
					<Link to="/women">Women</Link>
					<div className="mega-menu-container">{renderMegaMenu("WOMEN")}</div>
				</div>
			),
		},
		{
			key: "3",
			label: (
				<div className="menu-item-with-mega">
					<Link to="/kids">Kids</Link>
					<div className="mega-menu-container">{renderMegaMenu("KIDS")}</div>
				</div>
			),
		},
		{
			key: "4",
			label: (
				<div className="menu-item-with-mega">
					<Link to="/beauty">Beauty</Link>
					<div className="mega-menu-container">
						{renderMegaMenu("BEAUTY PRODUCTS")}
					</div>
				</div>
			),
		},
		{
			key: "5",
			label: <Link to="/blogs">Blog</Link>,
		},
		{
			key: "6",
			label: <Link to="/faq">FAQ</Link>,
		},
		{
			key: "7",
			label: (
				<Input
					placeholder="Search By Product"
					prefix={<SearchOutlined />}
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					onPressEnter={handleSearch}
				/>
			),
		},
		...(token
			? [
					{
						key: "8",
						label: (
							<Link to={getProfileNavigateLink()}>
								<UserOutlined /> My Account
							</Link>
						),
					},
					...(role === "user"
						? [
								{
									key: "9",
									label: (
										<Link to="/profile?tab=wishlist">
											<HeartOutlined /> Wishlist
										</Link>
									),
								},
								{
									key: "8",
									label: (
										<Link to="/cart">
											<ShoppingCartOutlined /> Cart
										</Link>
									),
								},
						  ]
						: []),

					{
						key: "9",
						label: (
							<div className="country-flag">
								Region : <span></span>
								<img
									src={`https://flagcdn.com/w40/${currentCountry.code.toLowerCase()}.png`}
									alt="sheagle-cc"
									width={"24px"}
								/>
							</div>
						),
					},
			  ]
			: []),
	];

	const fetchCart = async () => {
		try {
			const response = await fetch(`${apiurl}/cart/`, {
				method: "GET",
				headers: {
					Authorization: "Bearer " + token,
				},
			});
			const data = await response.json();
			setCount(data.items.length);
		} catch {
			console.log("Failed to fetch cart");
		}
	};

	return (
		<Layout>
			<Header className="user-layout-head">
				<div className="user-layout-header-logo">
					<Link to="/">
						<img alt="logo" src={logo} className="head-logo" />
					</Link>
					<Button
						className="switcher-button"
						type="text"
						icon={<img alt="switch" src={switchicon} />}
						onClick={() => setStoreDrawerVisible(true)}
					/>
				</div>
				<Button
					className="user-layout-mobile-menu-button"
					type="text"
					icon={<img alt="switch" src={menuicon} />}
					onClick={() => setDrawerVisible(true)}
				/>
				<Menu
					mode="horizontal"
					theme="light"
					selectedKeys={[defaultSelectedKeys()]}
					className="user-layout-menu"
					items={menuItems}
				/>
			</Header>
			<Drawer
				title="Switch Store"
				placement="left"
				className="switch-store-drawer"
				onClose={() => setStoreDrawerVisible(false)}
				open={storeDrawerVisible}>
				<Menu
					mode="inline"
					theme="light"
					items={storeMenuItems}
					className="store-menu-items"
				/>
			</Drawer>
			<Drawer
				title="Menu"
				placement="left"
				onClose={() => setDrawerVisible(false)}
				open={drawerVisible}>
				<Menu
					mode="inline"
					theme="light"
					selectedKeys={[defaultSelectedKeys()]}
					openKeys={openKeys}
					onOpenChange={(keys) => setOpenKeys(keys)}
					items={mobilemenuItems}
					className="user-layout-menu-mobile"
				/>
			</Drawer>
			<Layout>
				<div className="user-layout-content">
					{children}
					<FooterComponent />
				</div>
			</Layout>
			{location.pathname !== "/cart" && <CartIcon count={count} />}
		</Layout>
	);
};

export default Main;

import React from "react";
import Main from "../user/Layout";

const PrivacyPolicy = () => {
	return (
		<Main>
			<div className="terms-container">
				<h1 className="terms-title">Privacy Policy</h1>

				<div className="terms-content">
					<p>
						At Sheagle, your privacy is our utmost priority. We are committed to
						safeguarding your personal information and ensuring a secure and
						enjoyable shopping experience. This Privacy Policy explains how we
						collect, use, and protect your data when you interact with our
						e-commerce platform.
					</p>

					<section>
						<h2>1. Information We Collect</h2>
						<ul>
							<li>
								<strong>Personal Information:</strong> When you create an
								account, place an order, or subscribe to our newsletter, we
								collect essential details like your name, email address,
								shipping address, and payment information. This helps us
								personalize your experience and serve you better.
							</li>
							<li>
								<strong>Usage Data:</strong> We also gather information about
								how you navigate our website, such as your IP address, browser
								type, and pages visited, to enhance our platform and understand
								your preferences.
							</li>
						</ul>
					</section>

					<section>
						<h2>2. How We Use Your Information</h2>
						<ul>
							<li>
								<strong>Order Processing:</strong> Your information helps us
								efficiently process and deliver your orders.
							</li>
							<li>
								<strong>Communication:</strong> We use your email to update you
								on your order status, share promotional offers, and send
								newsletters tailored to your interests.
							</li>
							<li>
								<strong>Improvement:</strong> Your feedback enables us to
								enhance our services and your overall shopping experience.
							</li>
						</ul>
					</section>

					<section>
						<h2>3. Data Security</h2>
						<p>
							We take data security seriously. Robust measures are in place to
							protect your information from unauthorized access, disclosure,
							alteration, or destruction. Sensitive transactions are encrypted,
							and your data is stored securely.
						</p>
					</section>

					<section>
						<h2>4. Cookies</h2>
						<p>
							To improve your experience, we use cookies—small files stored on
							your device that remember preferences and enhance functionality.
							You can accept or decline cookies via your browser settings.
						</p>
					</section>

					<section>
						<h2>5. Sharing Your Information</h2>
						<p>
							We do not sell or trade your personal information. However, we may
							share data with trusted service providers who help operate our
							website and conduct business, ensuring they keep your information
							confidential.
						</p>
					</section>

					<section>
						<h2>6. Your Rights</h2>
						<p>
							You can access, update, or delete your personal information at any
							time. For assistance or inquiries about our privacy practices,
							contact us.
						</p>
					</section>

					<section>
						<h2>7. Changes to This Policy</h2>
						<p>
							We may update this Privacy Policy to reflect changes in our
							practices. Updates will be posted here, and we encourage periodic
							reviews to stay informed.
						</p>
					</section>

					<p>
						Thank you for trusting Sheagle. Your privacy is important to us, and
						we are dedicated to providing a safe shopping experience.
					</p>
				</div>
			</div>
		</Main>
	);
};

export default PrivacyPolicy;

import React, { useEffect, useState } from "react";
import {
	Tabs,
	Table,
	Button,
	Input,
	Space,
	Badge,
	Spin,
	Descriptions,
	Select,
} from "antd";
import { useParams } from "react-router-dom";
import {
	SearchOutlined,
	FilterOutlined,
	DeleteOutlined,
	PlusOutlined,
	MailOutlined,
	PhoneOutlined,
	EnvironmentOutlined,
} from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import Main from "./Layout";

const { Option } = Select;

const SpecificVendor = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [vendorData, setVendorData] = useState(null);

	const [invoices, setInvoices] = useState([]);
	const { apiurl, token } = useAuth();

	useEffect(() => {
		const fetchVendorDetails = async () => {
			try {
				const vendorResponse = await fetch(`${apiurl}/mydetails/vendor/`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
				const invoicesResponse = await fetch(`${apiurl}/vendor/myinvoices/`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				if (!vendorResponse.ok || !invoicesResponse.ok) {
					throw new Error("Failed to fetch data");
				}

				const vendorData = await vendorResponse.json();
				const invoicesData = await invoicesResponse.json();

				setVendorData(vendorData);
				setInvoices(invoicesData);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchVendorDetails();
	}, [id]);

	const renderTable = (dataSource, columns) => (
		<Table
			dataSource={dataSource}
			columns={columns}
			pagination={{ pageSize: 5 }}
			className="products-table"
		/>
	);

	if (loading) {
		return (
			<Spin size="large" style={{ display: "block", margin: "100px auto" }} />
		);
	}

	if (!vendorData) {
		return <div className="error">Failed to load vendor data</div>;
	}

	const invoiceColumns = [
		{
			title: "Invoice ID",
			dataIndex: "invoice_id",
			key: "invoice_id",
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
	];

	return (
		<Main>
			<div className="vendor-dashboard-container">
				<div className="vendor-header">
					<div className="vendor-image">
						<img src={apiurl + vendorData.logo} alt="Sheagle"></img>
					</div>
					<div className="vendor-details">
						<h1 className="vendor-name">
							{vendorData.business_name}
							<span> ({vendorData.username})</span>
						</h1>
						<div className="vendor-stats">
							<div className="stat-item">
								<div className="stat-value">{vendorData.total_revenue}</div>
								<div className="stat-label">Total Revenue</div>
							</div>
							<div className="stat-item">
								<div className="stat-value">{vendorData.total_products}</div>
								<div className="stat-label">Total Products</div>
							</div>
							<div className="stat-item">
								<div className="stat-value">{vendorData.total_orders}</div>
								<div className="stat-label">Total Orders</div>
							</div>
						</div>
					</div>
				</div>

				<Tabs defaultActiveKey="1" className="vendor-tabs">
					<Tabs.TabPane tab="Details" key="1">
						<div className="details-content">
							<div className="vendor-profile-card">
								<Descriptions
									className="vendor-profile-descriptions"
									column={1}
									title="Info">
									<Descriptions.Item label={<MailOutlined />}>
										{vendorData.contact_email}
									</Descriptions.Item>
									<Descriptions.Item label={<PhoneOutlined />}>
										{vendorData.contact_phone}
									</Descriptions.Item>
									<Descriptions.Item label={<EnvironmentOutlined />}>
										{vendorData.business_address_line},{" "}
										{vendorData.business_address_city},{" "}
										{vendorData.business_address_state},{" "}
										{vendorData.business_address_pincode}
									</Descriptions.Item>
								</Descriptions>
							</div>
							<div className="vendor-business-card">
								<Descriptions
									className="vendor-profile-descriptions"
									column={1}>
									<Descriptions.Item label="User Name">
										{vendorData.username}
									</Descriptions.Item>
									<Descriptions.Item label="Business Name">
										{vendorData.business_name}
									</Descriptions.Item>
									<Descriptions.Item label="Working Hours">
										{vendorData.operating_hours}
									</Descriptions.Item>
									<Descriptions.Item label="Year of Establishment">
										{vendorData.year_of_establishment}
									</Descriptions.Item>
									<Descriptions.Item label="Business Registration Certificate">
										<a
											href={
												apiurl + vendorData.business_registration_certificate
											}
											target="_blank"
											rel="noreferrer">
											View
										</a>
									</Descriptions.Item>
									<Descriptions.Item label="Owner Identification">
										<a
											href={apiurl + vendorData.owner_identification}
											target="_blank"
											rel="noreferrer">
											View
										</a>
									</Descriptions.Item>
								</Descriptions>
							</div>
						</div>
					</Tabs.TabPane>

					<Tabs.TabPane tab="Invoices" key="2">
						{renderTable(invoices, invoiceColumns)}
					</Tabs.TabPane>
				</Tabs>
			</div>
		</Main>
	);
};

export default SpecificVendor;

import React, { useEffect, useState } from "react";
import {
	Tabs,
	Table,
	Button,
	Input,
	Space,
	Badge,
	Spin,
	Descriptions,
	Select,
} from "antd";
import { useParams } from "react-router-dom";
import {
	SearchOutlined,
	FilterOutlined,
	DeleteOutlined,
	PlusOutlined,
	MailOutlined,
	PhoneOutlined,
	EnvironmentOutlined,
} from "@ant-design/icons";
import "./SpecificVendor.css";
import { useAuth } from "../utils/useAuth";
import Main from "./Layout";

const { Option } = Select;

const SpecificVendor = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [vendorData, setVendorData] = useState(null);
	const [products, setProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [orders, setOrders] = useState([]);
	const [invoices, setInvoices] = useState([]);
	const { apiurl, token } = useAuth();
	const [searchTerm, setSearchTerm] = useState("");
	const [categoryFilter, setCategoryFilter] = useState("");

	useEffect(() => {
		const fetchVendorDetails = async () => {
			try {
				const vendorResponse = await fetch(`${apiurl}/admin/vendor/${id}`);
				const ordersResponse = await fetch(`${apiurl}/admin/orders/${id}`);
				const productsResponse = await fetch(`${apiurl}/admin/products/${id}`);
				const invoicesResponse = await fetch(`${apiurl}/admin/invoices/${id}`);

				if (
					!vendorResponse.ok ||
					!ordersResponse.ok ||
					!productsResponse.ok ||
					!invoicesResponse.ok
				) {
					throw new Error("Failed to fetch data");
				}

				const vendorData = await vendorResponse.json();
				const ordersData = await ordersResponse.json();
				const productsData = await productsResponse.json();
				const invoicesData = await invoicesResponse.json();

				setVendorData(vendorData);
				setOrders(ordersData);
				setProducts(productsData);
				setFilteredProducts(productsData);
				setInvoices(invoicesData);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchVendorDetails();
	}, [id]);

	const formatDate = (dateobj) => {
		const date = new Date(dateobj);
		return date.toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric",
		});
	};

	const handleSearch = (e) => {
		const value = e.target.value.toLowerCase();
		setSearchTerm(value);
		filterProducts(value, categoryFilter);
	};

	const handleCategoryFilter = (value) => {
		setCategoryFilter(value);
		filterProducts(searchTerm, value);
	};

	const filterProducts = (search, category) => {
		let filtered = products;

		if (search) {
			filtered = filtered.filter((product) =>
				product.name.toLowerCase().includes(search)
			);
		}

		if (category) {
			filtered = filtered.filter(
				(product) => product.category && product.category.name === category
			);
		}

		setFilteredProducts(filtered);
	};

	const renderTable = (dataSource, columns) => (
		<Table
			dataSource={dataSource}
			columns={columns}
			pagination={{ pageSize: 5 }}
			className="products-table"
		/>
	);

	if (loading) {
		return (
			<Spin size="large" style={{ display: "block", margin: "100px auto" }} />
		);
	}

	if (!vendorData) {
		return <div className="error">Failed to load vendor data</div>;
	}

	const productColumns = [
		{
			title: "Product",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Category",
			dataIndex: ["category", "name"],
			key: "inventory",
		},
		{
			title: "No of Buys",
			dataIndex: "total_num_buyed",
			key: "total_num_buyed",
		},
		{
			title: "No Wishlisted",
			dataIndex: "total_num_wishlisted",
			key: "total_num_wishlisted",
		},
		{
			title: "Rating",
			dataIndex: "avg_rating",
			key: "avg_rating",
		},
	];

	const ordersColumns = [
		{
			title: "Order ID",
			dataIndex: ["order", "id"],
			key: "id",
		},
		{
			title: "No of Products",
			dataIndex: "order_items",
			key: "order_items",
			render: (order_items) => order_items.length,
		},
		{
			title: "Total",
			dataIndex: "total_price",
			key: "total",
		},
		{
			title: "Status",
			dataIndex: ["order", "status"],
			key: "status",
			render: (status) => (
				<Badge
					status={
						status === "Processing"
							? "processing"
							: status === "delivered"
							? "success"
							: "default"
					}
					text={status}
				/>
			),
		},
		{
			title: "Date",
			dataIndex: ["order", "created_at"],
			key: "date",
			render: (date) => formatDate(date),
		},
	];

	const invoiceColumns = [
		{
			title: "Invoice ID",
			dataIndex: "invoice_id",
			key: "invoice_id",
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
	];

	return (
		<Main>
			<div className="vendor-dashboard-container">
				<div className="vendor-header">
					<div className="vendor-image">
						<img src={apiurl + vendorData.logo} alt="Sheagle"></img>
					</div>
					<div className="vendor-details">
						<h1 className="vendor-name">
							{vendorData.business_name}
							<span> ({vendorData.username})</span>
						</h1>
						<div className="vendor-stats">
							<div className="stat-item">
								<div className="stat-value">{vendorData.total_revenue}</div>
								<div className="stat-label">Total Revenue</div>
							</div>
							<div className="stat-item">
								<div className="stat-value">{vendorData.total_products}</div>
								<div className="stat-label">Total Products</div>
							</div>
							<div className="stat-item">
								<div className="stat-value">{vendorData.total_orders}</div>
								<div className="stat-label">Total Orders</div>
							</div>
						</div>
					</div>
				</div>

				<Tabs defaultActiveKey="1" className="vendor-tabs">
					<Tabs.TabPane tab="Products" key="1">
						<div className="products-actions">
							<Space>
								<Select
									placeholder="Filter by Category"
									onChange={handleCategoryFilter}
									style={{ width: 200 }}>
									{[...new Set(products.map((p) => p.category?.name))]
										.filter((name) => name)
										.map((name) => (
											<Option key={name} value={name}>
												{name}
											</Option>
										))}
								</Select>
								<Input
									placeholder="Search..."
									prefix={<SearchOutlined />}
									value={searchTerm}
									onChange={handleSearch}
									className="search-input"
								/>
							</Space>
						</div>
						{renderTable(filteredProducts, productColumns)}
					</Tabs.TabPane>
					<Tabs.TabPane tab="Orders" key="2">
						{renderTable(orders, ordersColumns)}
					</Tabs.TabPane>
					<Tabs.TabPane tab="Invoices" key="3">
						{renderTable(invoices, invoiceColumns)}
					</Tabs.TabPane>
					<Tabs.TabPane tab="Details" key="4">
						<div className="details-content">
							<div className="vendor-profile-card">
								<Descriptions
									className="vendor-profile-descriptions"
									column={1}
									title="Info">
									<Descriptions.Item label={<MailOutlined />}>
										{vendorData.contact_email}
									</Descriptions.Item>
									<Descriptions.Item label={<PhoneOutlined />}>
										{vendorData.contact_phone}
									</Descriptions.Item>
									<Descriptions.Item label={<EnvironmentOutlined />}>
										{vendorData.business_address_line},{" "}
										{vendorData.business_address_city},{" "}
										{vendorData.business_address_state},{" "}
										{vendorData.business_address_pincode}
									</Descriptions.Item>
								</Descriptions>
							</div>
							<div className="vendor-business-card">
								<Descriptions
									className="vendor-profile-descriptions"
									column={1}>
									<Descriptions.Item label="User Name">
										{vendorData.username}
									</Descriptions.Item>
									<Descriptions.Item label="Business Name">
										{vendorData.business_name}
									</Descriptions.Item>
									<Descriptions.Item label="Working Hours">
										{vendorData.operating_hours}
									</Descriptions.Item>
									<Descriptions.Item label="Year of Establishment">
										{vendorData.year_of_establishment}
									</Descriptions.Item>
									<Descriptions.Item label="Business Registration Certificate">
										<a
											href={
												apiurl + vendorData.business_registration_certificate
											}
											target="_blank"
											rel="noreferrer">
											View
										</a>
									</Descriptions.Item>
									<Descriptions.Item label="Owner Identification">
										<a
											href={apiurl + vendorData.owner_identification}
											target="_blank"
											rel="noreferrer">
											View
										</a>
									</Descriptions.Item>
								</Descriptions>
							</div>
						</div>
					</Tabs.TabPane>
				</Tabs>
			</div>
		</Main>
	);
};

export default SpecificVendor;
